import http from 'services/http';

export const fetchSetupIntent = (merchantId) => http
  .get(`v1/organizations/{{organizationId}}/merchants/${merchantId}/extra-commission/setup-intent`)
  .then(({ data }) => data);

export const fetchMerchantCard = (merchantId) => http
  .get(`v1/organizations/{{organizationId}}/merchants/${merchantId}/extra-commission/card`)
  .then(({ data }) => data);

export const deleteMerchantCard = (merchantId) => http
  .delete(`v1/organizations/{{organizationId}}/merchants/${merchantId}/extra-commission/card`)
  .then(({ data }) => data);
