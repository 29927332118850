import http from 'services/http';
import { generateQuery } from 'utils';

export const getAllCoupons = (searchKey, page, status, organizationId) => {
  const query = generateQuery([
    searchKey ? `search=${searchKey}` : '',
    page > 1 ? `page=${page}` : '',
    status != undefined ? `is_disabled=${status}` : '',
    organizationId ? 'organization_id={{organizationId}}' : '',
  ]);
  return http.get(`v1/coupons/${query}`).then((res) => res.data.data);
};
export const deleteCouponById = (id) => http.delete(`v1/coupons/${id}/?organization_id={{organizationId}}`).then((res) => res.data.data);

export const createCoupon = (data) => http.post('v1/coupons/?organization_id={{organizationId}}', data);

export const editCoupon = (data) => http.put(`v1/coupons/${data.id}/?organization_id={{organizationId}}`, data);

export const getCouponById = (id) => http.get(`v1/coupons/${id}/?organization_id={{organizationId}}`).then((res) => res.data.data);
