import http from 'services/http';

const mockData = new Array(50).fill(
  {
    id: 71,
    sign: -1,
    amount: 2000,
    type: 'PURCHASE',
    created_at: '2022-11-23T12:49:14.524973Z',
    updated_at: '2022-11-23T12:49:14.524998Z',
    description: null,
    from_wallet: 169,
    to_wallet: 1021,
    author: null,
    author_customer: null,
  },
);

export const fetchCreditHistory = (id) => http.get(
  `/v1/billing/organizations/{{organizationId}}/customers/${id}/transactions`,
).then((res) => res.data);
// export const fetchCreditHistory = () => new Promise((resolve) => {
//   setTimeout(
//     () => resolve({
//       data: mockData,
//       total: mockData.length,
//     }),
//     1000,
//   );
// });
