import { Button, Divider } from 'antd';
import { getCustomerById } from 'api/customers';
import BadgeLabel from 'components/common/BadgeLabel';
import { PERMISSIONS } from 'constants/permissions';
import Addresses from 'containers/CustomerDetail/Addresses';
import { ModalContext } from 'context/modalContext';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import { getCustomerOrdersById } from '../../../api/customers';
import SkeletonLoading from '../../../components/common/SkeletonLoading';
import usePagination from '../../../hooks/usePagination';
import {
  errorHandling, formatAmount, getData, priceTransformer,
} from '../../../utils';
import BannedSwitch from './BanndedSwitch';
import { StyledWrapper } from './styles';
import Table from './Table';
import TaxFreeSwitch from './TaxFreeSwitch';

function Overview() {
  const { handleModal } = useContext(ModalContext);
  const [customerDataloading, setCustomerDataLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [customerOrdersloading, setCustomerOrdersLoading] = useState(true);
  const [orders, setOrders] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const history = useHistory();
  const hasCustomerAccess = useHasAnyOfPermissions([PERMISSIONS.CUSTOMERS]);
  const { id } = useParams();

  const CUSTOMER_DETAILS_TABS = [
    {
      title: 'Wallet balance',
      dataGetter: (data) => formatAmount(data?.wallet?.balance || 0, { isCent: true }),
      badgedataGetter: (data) => (
        <Button
          size="small"
          color="sucess"
          onClick={() => {
            history.push(`/console/customers/${data?.id}/adjust-balance`);
          }}
        >
          Adjust
        </Button>
      ),
    },
    {
      title: 'Last completed order',
      dataGetter: (data) => handleformatDate(data?.last_order_date),
      badgedataGetter: (data) => null,
    },
    {
      title: 'Total spent',
      dataGetter: (data) => priceTransformer(data?.total_spent),
      badgedataGetter: (data) => `${data?.orders_count} orders`,
    },
  ];

  useEffect(() => {
    getCustomerData();
    getCustomerOrders();
  }, [hasCustomerAccess]);

  const getCustomerData = () => {
    setCustomerDataLoading(true);
    getCustomerById(id)
      .then((res) => {
        setCustomer(res.data);
        setCustomerDataLoading(false);
      })
      .catch((e) => {
        captureException(e);
        setCustomerDataLoading(false);
      });
  };

  const getCustomerOrders = () => {
    if (!hasCustomerAccess) return;
    setCustomerOrdersLoading(true);
    setOrders([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    getCustomerOrdersById(id, page)
      .then((res) => {
        setOrders(getData(res)?.results);
        setPagination({
          count: getData(res)?.count,
          next: getData(res)?.next,
          previous: getData(res)?.previous,
        });
        setCustomerOrdersLoading(false);
      })
      .catch((e) => {
        captureException(e);
        setCustomerOrdersLoading(false);
        errorHandling(e);
      });
  };

  const {
    setPage, page, pagination, setPagination, paginationEl,
  } =
    usePagination(getCustomerOrders, customerOrdersloading);

  const handleStatus = (status) => (
    <BadgeLabel
      label={status}
      classes={`status status--${status?.toLowerCase()}`}
    />
  );

  const handleformatDate = (d) => (d ? moment(d).format('MMM D, hh:mm A') : '-');

  const handleFormatSignUpDate = (d) => (d ? moment(d).format('MMM D, YYYY') : '-');

  const handleGotToEditPage = (customerId, address) => {
    // handleModal();
    history.push(`/console/customer-address/${customerId}/${address?.id}`);
  };

  const handleOpenAddressesModal = (addresses, customerId) => {
    handleModal(
      <Addresses
        addresses={addresses}
        customerId={customerId}
        goToEditPage={(customerId, address) => handleGotToEditPage(customerId, address)}
      />,
      false,
      'modal-close-btn',
    );
  };

  if (!customerDataloading && !customer) {
    return null;
  }

  return (
    <StyledWrapper>
      {/* Title */}
      <div className="flex-row-end mb-2">
        <div className="customer-name">
          {customerDataloading ? (
            <SkeletonLoading
              borderRadius="2px"
              width="182px"
              height="34px"
              count={1}
            />
          ) : (
            customer?.name
          )}
        </div>

        <div className="customer-status">
          {customerDataloading ? (
            <SkeletonLoading
              borderRadius="2px"
              width="53px"
              height="24px"
              count={1}
            />
          ) : (
            handleStatus(customer?.status)
          )}
        </div>
      </div>
      {/* Order Tabs */}
      <div className="stat-tabs">
        {CUSTOMER_DETAILS_TABS.map((tab, index) => (
          <div className="tab" key={index}>
            <div className="tab-row-left">{tab.title}</div>
            <div className="tab-row">
              <div className="tab-desc">
                {customerDataloading ? (
                  <SkeletonLoading
                    borderRadius="2px"
                    width="133px"
                    height="24px"
                    count={1}
                  />
                ) : (
                  tab.dataGetter(customer)
                )}
              </div>
              {tab.badgedataGetter(customer) ? (
                <div className="tab-right">
                  {customerDataloading ? (
                    <SkeletonLoading
                      borderRadius="2px"
                      width="59px"
                      height="16px"
                      count={1}
                    />
                  ) : (
                    tab.badgedataGetter(customer)
                  )}
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      {/* Customer Details */}
      <div className="details-title">Customer details</div>
      <div className="details-grid">
        <div className="details-cell">
          <div className="details-cell-left">Email</div>
          <div className="details-cell-right details-cell-colored strong">
            {customerDataloading ? (
              <SkeletonLoading
                borderRadius="2px"
                width="148px"
                height="18px"
                count={1}
              />
            ) : (
              customer.email
            )}
          </div>
        </div>
        <div className="details-cell">
          <div className="details-cell-left">Phone</div>
          <div className="details-cell-right">
            {customerDataloading ? (
              <SkeletonLoading
                borderRadius="2px"
                width="148px"
                height="18px"
                count={1}
              />
            ) : (
              customer.phone
            )}
            {' '}
          </div>
        </div>
        <div className="details-cell">
          <div className="details-cell-left">Address</div>
          <div className="details-cell-right">
            {customerDataloading ? (
              <SkeletonLoading
                borderRadius="2px"
                width="148px"
                height="18px"
                count={1}
              />
            ) : (
              customer?.customer_addresses[
                customer?.customer_addresses?.length - 1
              ]?.address
            )}
            {' '}
            {customer?.customer_addresses?.length > 0 ? (
              <span
                className={`more-address ${!hasCustomerAccess && customer?.customer_addresses?.length === 1 && 'd-none'}`}
                onClick={() => (customer.customer_addresses.length === 1 ?
                  handleGotToEditPage(customer?.id, customer.customer_addresses[0])
                  : handleOpenAddressesModal(
                    customer?.customer_addresses,
                    customer?.id,
                  ))}
              >
                {customer.customer_addresses.length === 1 ? 'Edit' : 'More addresses'}
              </span>
            ) : null}
          </div>
        </div>

        <div className="details-cell">
          <div className="details-cell-left">Signup date</div>
          <div className="details-cell-right">
            {customerDataloading ? (
              <SkeletonLoading
                borderRadius="2px"
                width="148px"
                height="18px"
                count={1}
              />
            ) : (
              handleFormatSignUpDate(customer.created_at)
            )}
            {' '}
          </div>
        </div>
      </div>
      <div className="details-title">Order Access</div>
      <BannedSwitch customer={customer} onChange={(is_banned) => setCustomer((c) => ({ ...c, is_banned }))} />
      <Divider />
      <div className="details-title">Tax-Free Order Eligibility</div>
      <TaxFreeSwitch customer={customer} onChange={(is_tax_exempt) => setCustomer((c) => ({ ...c, is_tax_exempt }))} />
      {hasCustomerAccess && (
        <>
          <Divider />
          {/* Orders Table */}
          <div className="details-title">Orders history</div>
          <div className="table-box">
            <Table
              loading={customerOrdersloading}
              pagination={pagination}
              items={orders}
              refetch={getCustomerOrders}
            />
            <div className="pagination">{paginationEl}</div>
          </div>
        </>
      )}

    </StyledWrapper>
  );
}

export default Overview;
