import Layout from 'components/Layout';
import { HAS_CHRISTMAS_THEME } from 'constants/index';
import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import {
  Card, Typography, Form, Input, Button, message,
} from 'antd';

import { ChristmasOCLogoIcon, OCLogoIcon } from 'assets/icons';
import http, { getHttpErrorMessage } from 'services/http';
import { captureException } from 'utils/errors';

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = (data) => {
    setLoading(true);
    http.post('user/rest-auth/password/reset/', data, {
      headers: {
        Authorization: '',
      },
    })
      .then((res) => {
        message.success('Please check your mail box.');
        history.push('/');
        setLoading(false);
      })
      .catch((e) => {
        captureException(e);
        setLoading(false);
        message.error(getHttpErrorMessage(e));
      });
  };

  return (
    <Layout hasSidebar={false}>
      <div className="w-screen h-screen flex items-center justify-center">
        <Card className="w-96">
          <div className="flex items-center justify-center mb-5">
            {HAS_CHRISTMAS_THEME ? <ChristmasOCLogoIcon /> : <OCLogoIcon />}
          </div>
          <Typography.Title level="1" className="mt-3" style={{ fontSize: 24 }}>
            Reset Password
          </Typography.Title>
          <Form
            onFinish={handleSubmit}
            className="mt-6"
            validateTrigger="onSubmit"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input placeholder="email" />
            </Form.Item>

            <Link to="/">Sign in with Email</Link>
            <Form.Item>
              <Button
                block
                loading={loading}
                disabled={loading}
                type="primary"
                htmlType="submit"
                className="mt-5"
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Layout>
  );
}

export default ResetPassword;
