import React from 'react';
import Icon from '@ant-design/icons';

const StoreSVGIcon = function () {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M45.1094 73.5109C45.1094 74.0673 45.5606 74.5189 46.1174 74.5189H52.4745C53.0312 74.5189 53.4825 74.0673 53.4825 73.5109V59.7954C53.4825 59.2386 53.0312 58.7874 52.4745 58.7874H46.1174C45.5606 58.7874 45.1094 59.2386 45.1094 59.7954V73.5109Z" fill="#DAEADE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.9868 61.9127H27.2538C25.8628 61.9127 24.7338 60.7837 24.7338 59.3927C24.7338 58.0016 25.8628 56.8727 27.2538 56.8727H30.9868C32.3778 56.8727 33.5068 58.0016 33.5068 59.3927C33.5068 60.7837 32.3778 61.9127 30.9868 61.9127ZM68.5247 47.2698H16.4749C15.5442 47.2698 14.7949 48.0191 14.7949 48.9498V66.6234C14.7949 70.9779 18.3364 74.5194 22.6943 74.5194H39.0609C39.6177 74.5194 40.0689 74.0681 40.0689 73.5114V56.2679C40.0689 54.8768 41.1979 53.7479 42.5889 53.7479H56.002C57.3931 53.7479 58.522 54.8768 58.522 56.2679V73.5114C58.522 74.0681 58.9733 74.5194 59.53 74.5194H62.302C66.66 74.5194 70.2048 70.9779 70.2048 66.6234V48.9498C70.2048 48.0191 69.4521 47.2698 68.5247 47.2698Z" fill="#DAEADE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M73.2282 36.8794L71.135 25.6872C70.5806 22.7338 67.99 20.5901 64.9794 20.5901H20.0628C17.0523 20.5901 14.4651 22.7338 13.9107 25.6805L11.7804 36.8021C11.4411 38.2301 11.7737 39.7152 12.6876 40.871C13.6083 42.0336 14.9859 42.6989 16.4643 42.6989H68.5276C70.006 42.6989 71.3836 42.0336 72.3042 40.871C73.2182 39.7152 73.5508 38.2334 73.2282 36.8794Z" fill="#DAEADE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.5395 14.8808H58.4561C59.8472 14.8808 60.9761 13.7519 60.9761 12.3608C60.9761 10.9698 59.8472 9.84082 58.4561 9.84082H26.5395C25.1485 9.84082 24.0195 10.9698 24.0195 12.3608C24.0195 13.7519 25.1485 14.8808 26.5395 14.8808Z" fill="#DAEADE" />
    </svg>

  );
};
function StoreIcon(props) {
  return (
    <Icon component={StoreSVGIcon} {...props} />
  );
}

export default StoreIcon;
