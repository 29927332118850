import React from 'react';

import DebounceSelect from 'components/Select/DebounceSelect';
import http from 'services/http';
import { generateURLWithParams } from 'utils';
import { captureException } from 'utils/errors';

function TagsInput(props) {
  const fetchTags = (search) => http(generateURLWithParams({
    url: 'v1/tags/',
    params: { search, query: '{id,title}' },
  })).then(({ data: { data } }) => [...data.results, ...props.selectedTags].map(({ title, id }) => ({ value: id, label: title })))
    .catch((e) => {
      captureException(e);
    });

  return (
    <DebounceSelect
      mode="multiple"
      allowClear
      placeholder="Search tags"
      fetchOptions={fetchTags}
      showSearch
      style={{ width: '100%', height: 40, marginTop: 8 }}
      labelInValue={false}
      {...props}
    />

  );
}

export default TagsInput;
