import { Tag, Tooltip } from 'antd';
import { DeliveryIcon, PickupIcon } from 'assets/icons';
import BadgeLabel from 'components/common/BadgeLabel';
import { OrderStatusLabel } from 'constants/status';
import ColumnWithTooltip from 'containers/Reports/components/ColumnWithTooltip';
import { renderBoldPriceColumn, renderPriceColumn } from 'containers/Reports/utils';
import moment from 'moment';
import { getPaymentType } from 'utils/orders';

const columns = (isMobile) => [
  {
    title: 'ORDER NUMBER',
    dataIndex: 'number',
    key: 'number',
    width: '140px',
    fixed: isMobile ? false : 'left',
  },
  {
    title: 'MERCHANT',
    dataIndex: 'merchant',
    key: 'merchant',
    width: '200px',
    ellipsis: true,
    fixed: isMobile ? false : 'left',
  },
  {
    title: 'LOCATION',
    dataIndex: 'location',
    key: 'location',
    width: '160px',
    ellipsis: true,
  },
  {
    title: 'MERCHANT TYPE',
    dataIndex: 'merchant_type',
    key: 'merchant_type',
    width: '140px',
    render: (value) => <Tag>{value}</Tag>,
  },
  {
    title: 'DATE',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '160px',
    render: (value) => moment(value).utc(true).format('MMM D, hh:mm A'),
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width: '160px',
    render: (value) => OrderStatusLabel[value],
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
    key: 'type',
    width: '120px',
    render: (value) => (
      <BadgeLabel
        label={value}
        leadingIcon={value === 'delivery' ? <DeliveryIcon /> : <PickupIcon />}
        classes="type-badge"
      />
    ),
  },
  {
    title: 'ORDER SOURCE',
    dataIndex: 'order_source',
    key: 'order_source',
    width: '160px',
    render: (value) => (value ? <Tag>{value?.toUpperCase()}</Tag> : null),
  },
  {
    title: 'PAYMENT TYPE',
    dataIndex: 'payment_type',
    key: 'payment_type',
    width: '130px',
    render: (value) => (value >= 0 ? <Tag>{getPaymentType(value)}</Tag> : null),
  },
  {
    title: () => <ColumnWithTooltip title="AMOUNT" description="Total charged to customer" />,
    dataIndex: 'amount',
    key: 'amount',
    width: '120px',
    render: renderBoldPriceColumn,
  },
  {
    title: () => <ColumnWithTooltip title="M.NET" description="Merchant net" />,
    dataIndex: 'merchant_portion',
    key: 'merchant_portion',
    width: '110px',
    render: renderPriceColumn,
  },
  {
    title: 'COMMISSION',
    dataIndex: 'commission',
    key: 'commission',
    width: '130px',
    render: renderPriceColumn,
  },
  {
    title: 'MARKUP',
    dataIndex: 'markup',
    key: 'markup',
    width: '110px',
    render: renderPriceColumn,
  },
  {
    title: 'DELIVERY FEE',
    dataIndex: 'delivery_fee',
    key: 'delivery_fee',
    width: '120px',
    render: renderPriceColumn,
  },
  {
    title: 'SERVICE FEE',
    dataIndex: 'service_fee',
    key: 'service_fee',
    width: '120px',
    render: renderPriceColumn,
  },
  {
    title: 'TAX',
    dataIndex: 'tax',
    key: 'tax',
    width: '90px',
    render: renderPriceColumn,
  },
  {
    title: 'MARKUP TAX',
    dataIndex: 'markup_tax',
    key: 'markup_tax',
    width: '120px',
    render: renderPriceColumn,
  },
  {
    title: 'TIP',
    dataIndex: 'tip',
    key: 'tip',
    width: '90px',
    render: renderPriceColumn,
  },
  {
    title: 'PAYMENT FEE',
    dataIndex: 'payment_fee',
    key: 'payment_fee',
    width: '130px',
    render: renderPriceColumn,
  },
  {
    title: 'MERCHANT PAYMENT FEE',
    dataIndex: 'merchant_payment_fee',
    key: 'merchant_payment_fee',
    width: '130px',
    render: renderPriceColumn,
  },
  {
    title: 'RDS PAYMENT FEE',
    dataIndex: 'rds_payment_fee',
    key: 'rds_payment_fee',
    width: '130px',
    render: renderPriceColumn,
  },
  {
    title: 'EDITED',
    dataIndex: 'edited',
    key: 'edited',
    width: '80px',
    render: (value) => (value ? 'YES' : 'NO'),
  },
  {
    title: 'ADJUSTMENT',
    dataIndex: 'adjustment',
    key: 'adjustment',
    width: '130px',
    render: renderPriceColumn,
  },
  {
    title: 'SELF NOTES',
    dataIndex: 'self_notes',
    key: 'self_notes',
    width: '200px',
    ellipsis: {
      showTitle: false,
    },
    render: (note) => (
      <Tooltip placement="left" title={note}>
        {note}
      </Tooltip>
    ),
  },
  {
    title: 'INDEPENDENCY',
    dataIndex: 'has_integration_error',
    key: 'has_integration_error',
    width: '200px',
    ellipsis: {
      showTitle: false,
    },
    render: (hasError) => (
      hasError ? 'YES' : 'NO'
    ),
  },
];
export default columns;
