import { Space, Tabs } from 'antd';
import Layout from 'components/Layout';
import React from 'react';
import { useParams } from 'react-router-dom';
import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import ModifierProvider from './ModifierProvider';
import ModifierOverview from './Modifiers';
import MenuOverview from './Overview';

function MenuManagement() {
  const { id: merchantId } = useParams();

  const items = [
    {
      key: '1',
      label: 'Overview',
      children: <MenuOverview />,
    },
    {
      key: '2',
      label: 'Modifiers',
      children: <ModifierOverview />,
    },
  ];

  return (
    <Layout header={(
      <Layout.Header
        title="Menu Management"
        backTitle="Merchant info"
        backPath={`/console/merchants/${merchantId}`}
      />
    )}
    >
      <ModifierProvider>
        <Tabs defaultActiveKey="1" items={items} />
      </ModifierProvider>
    </Layout>
  );
}

export default withGuard(MenuManagement, [PERMISSIONS.MERCHANTS], { redirect: true });
