import http from 'services/http';
import { apiUrlDeprecated } from 'constants/index';
import { generateURLWithParams } from '../utils';

export const getOrders = (params) => {
  const url = generateURLWithParams('organizations/{{organizationId}}/orders/', {
    ...params,
    separate_qty_from_item_names_for_migrated_orders: 1,
  });
  return http.get(url, { baseURL: apiUrlDeprecated });
};

export const updateOrderState = (id, data) => http.post(`v1/orders/update/${id}/`, data).then((res) => res.data);
export const estimateOrder = (id) => http.get(`v1/orders/estimate/${id}/`).then((res) => res.data);
export const getFinalCustomerItemPrice =
  ({ id, price }) => http
    .get(`v1/items/${id}/imaginary-price/?price=${price}`)
    .then((res) => res.data);
export const getFinalCustomerModifierPrice =
  ({ id, price }) => http
    .get(`v1/modifier-options/${id}/imaginary-price/?price=${price}`)
    .then((res) => res.data);
export const approveOrder = (id) => http.post(`v1/orders/${id}/approve-order/`).then((res) => res.data);
export const isRideServiceAvailable = (id) => http
  .get(`v1/orders/is-service-available/${id}/`)
  .then((res) => res.data);

export const onboardMerchant = (data) => async () => await http
  .post('v1/billing/merchant/onboard_merchant/', data)
  .then((res) => res.data);

export const getOrderDetailByOrderId = (id) => http.get(`v1/orders/${id}/`).then((res) => res.data);
