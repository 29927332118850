import Button from 'components/common/Button';
import React, { useContext, useState } from 'react';
import { captureException } from 'utils/errors';
import { deleteNotificationById } from '../../../api/marketing';
import { ModalContext } from '../../../context/modalContext';
import { StyledWrapper } from './styles';

function DeleteModal({ row, reload, ...props }) {
  const { handleModal } = useContext(ModalContext);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDelete = (row) => {
    setDeleteLoading(true);
    deleteNotificationById(row?.id).then((res) => {
      if (res.status === 204) {
        setDeleteLoading(false);
        handleModal();
        reload();
      }
    })
      .catch((e) => {
        captureException(e);
        setDeleteLoading(false);
      });
  };

  return (
    <StyledWrapper>
      {' '}
      <div className="delete-modal">
        <p className="title">Delete notification</p>
        <p className="qa">
          Are you sure you want to delete this notification? This can't be
          undone.
        </p>
        <p className="desc">{row?.title}</p>
        <div className="btns">
          <Button
            onClick={() => handleModal()}
            label="Cancel"
            type="neutral"
            classes="btn-cancel"
          />
          <Button
            onClick={() => handleDelete(row)}
            label="Delete"
            type="neutral"
            classes="btn-delete"
            loading={deleteLoading}
          />
        </div>
      </div>
    </StyledWrapper>
  );
}

export default DeleteModal;
