import { Table, Typography } from 'antd';
import { renderBoldPriceColumn } from 'containers/Reports/utils';
import React from 'react';

const { Text } = Typography;

function DetailSummary({
  subTotal, tax, commission, payment_fee, net,
}) {
  return (
    <Table.Summary fixed>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={4} />
        <Table.Summary.Cell index={4} align="right">Total:</Table.Summary.Cell>
        <Table.Summary.Cell index={5}>
          <Text type="success">{renderBoldPriceColumn(subTotal)}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6}>
          <Text type="success">{renderBoldPriceColumn(tax)}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7}>
          <Text type="danger">
            -
            {renderBoldPriceColumn(commission)}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={8}>
          <Text type="danger">
            -
            {renderBoldPriceColumn(payment_fee)}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={9}>
          <Text type="success">{renderBoldPriceColumn(net)}</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
}
export default DetailSummary;
