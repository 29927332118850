import React, {
  useEffect, useState, useReducer, useContext,
} from 'react';
import { ReactComponent as DropdownIcon } from 'assets/image/dropdown.svg';
import Input from 'components/common/Input';
import Textarea from 'components/common/Textarea';
import Dropdown from 'components/common/Dropdown';
import BadgeLabel from 'components/common/BadgeLabel';
import RadioButtonGroup from 'components/common/RadioButtonGroup';
import Button from 'components/common/Button';
import { useHistory, useLocation } from 'react-router';
import DatePickerComponent from 'components/common/DatePicker';
import Checkbox from 'components/common/Checkbox';
import TimePicker from 'components/common/TimePicker';
import moment from 'moment';
import { captureException } from 'utils/errors';
import { createNotification, editNotification } from '../../../api/marketing';
import { ModalContext } from '../../../context/modalContext';
import { StyledWrapper } from './styles';
import DeleteModal from '../../Marketing/DeleteModal';

function Form({ data, ...props }) {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const SENDING_TIME_OPTIONS = [
    {
      title: 'Now',
      element: <div className="">Now</div>,
    },
    {
      title: 'Scheduled',
      element: <div className="">Scheduled</div>,
    },
  ];
  const [sendingTimeTypeTitle, setSendingTimeTypeTitle] = useState(
    SENDING_TIME_OPTIONS[0].title,
  );

  const TARGET_OPTIONS = [
    {
      id: 0,
      title: 'All customers',
    },
    {
      id: 1,
      title: 'Customer segments',
    },
  ];
  const [target, setTarget] = useState(TARGET_OPTIONS[0]);

  const customerSegmentReducer = (state, action) => {
    switch (action.type) {
    case customerSegmentsArr[0].title:
      return state.map((c, i) => {
        if (c.title === customerSegmentsArr[0].title) {
          return {
            ...c,
            checked: action.payload,
          };
        }
        return c;
      });
    case customerSegmentsArr[1].title:
      return state.map((c, i) => {
        if (c.title === customerSegmentsArr[1].title) {
          return {
            ...c,
            checked: action.payload,
          };
        }
        return c;
      });
    case customerSegmentsArr[2].title:
      return state.map((c, i) => {
        if (c.title === customerSegmentsArr[2].title) {
          return {
            ...c,
            checked: action.payload,
          };
        }
        return c;
      });
    case customerSegmentsArr[3].title:
      return state.map((c, i) => {
        if (c.title === customerSegmentsArr[3].title) {
          return {
            ...c,
            checked: action.payload,
          };
        }
        return c;
      });
    case 'all':
      return state.map((c, i) => ({
        ...c,
        checked: true,
      }));
    case 'none':
      return state.map((c, i) => ({
        ...c,
        checked: false,
      }));

    default:
      return state;
    }
  };
  const customerSegmentsArr = [
    { title: 'New', checked: !data?.id },
    { title: 'Active', checked: !data?.id },
    { title: 'Inactive', checked: !data?.id },
    { title: 'Lost', checked: !data?.id },
  ];
  const [customerSegment, dispatchCustomerSegment] = useReducer(
    customerSegmentReducer,
    customerSegmentsArr,
  );

  const {
    modal, handleModal, modalContent, hideCloseBtn, classes,
  } =
    useContext(ModalContext);

  useEffect(() => {
    if (data?.id) {
      setTitle(isEdit() ? data?.title : `${data?.title} duplicate`);
      setText(data?.body);
      if (data?.receivers?.split(', ')?.length === 4) {
        setTarget(TARGET_OPTIONS[0]);
      } else {
        setTarget(TARGET_OPTIONS[1]);
      }
      for (let i = 0; i < data?.receivers?.split(', ').length; i++) {
        const element = data?.receivers?.split(', ')[i];
        dispatchCustomerSegment({ type: element, payload: true });
      }
      setSendingTimeTypeTitle(SENDING_TIME_OPTIONS[1].title);
      setDate(moment(data?.send_at));
      setTime(moment(data?.send_at));
    }
  }, [data]);

  useEffect(() => {
    if (sendingTimeTypeTitle === SENDING_TIME_OPTIONS[0].title && time) {
      setDate('');
      setTime('');
    }
  }, [sendingTimeTypeTitle]);

  const checkIsValid = () => {
    let valid = true;
    if (!title || !text) {
      valid = false;
    }
    return valid;
  };

  const isEdit = () => {
    if (data?.id && location.pathname.includes('edit')) {
      return true;
    }
    return false;
  };

  const handleSave = () => {
    if (!checkIsValid()) {
      return;
    }
    const notifData = {
      title,
      body: text,
      receivers: target.id === 0 ? '' : customerSegment
        ?.map((s) => {
          if (s.checked) {
            return s.title;
          }
        })
        .filter((s) => s)
        .join(', '),
      send_at:
        date && time
          ? new Date(moment(`${moment(date).format('ddd MMM D YYYY')} ${time}`))
          : new Date(),
      ttl_unit: 'week',
      ttl_amount: 2,
    };
    if (isEdit()) {
      setSaveBtnLoading(true);
      editNotification({ id: data?.id, data: notifData })
        .then((res) => {
          setSaveBtnLoading(false);
          handleGoToListPage();
        })
        .catch((e) => {
          captureException(e);
          setSaveBtnLoading(false);
        });
    } else {
      setSaveBtnLoading(true);
      createNotification(notifData).then((res) => {
        setSaveBtnLoading(false);
        handleGoToListPage();
      })
        .catch((e) => {
          captureException(e);
          setSaveBtnLoading(false);
        });
    }
  };

  const handleGoToListPage = () => {
    history.push('/console/marketing');
  };

  const handleCancel = () => {
    handleGoToListPage();
  };

  const handleDelete = () => {
    handleModal(<DeleteModal row={data} reload={handleGoToListPage} />);
  };

  const handleTextChange = (val) => {
    if (val?.length <= 148) {
      setText(val);
    }
  };

  const handleChangeTarget = (t) => {
    setTarget(t);
    if (t.title === TARGET_OPTIONS[0].title) {
      dispatchCustomerSegment({ type: 'all' });
    } else {
      dispatchCustomerSegment({ type: 'none' });
    }
  };

  return (
    <StyledWrapper>
      {/* Name */}
      <div className="box box--top">
        <h6 className="box-title">Sending time</h6>
        <h6 className="box-sub-title">Title</h6>
        <Input
          type="text"
          id="campaign-name"
          value={title}
          onChange={setTitle}
          placeholder="e.g. Black Friday"
          inputClasses="name-input"
        />
        <div className="text-box">
          <h6 className="box-sub-title input-margin">Text</h6>
          <Textarea
            type="text"
            id="campaign-text"
            value={text}
            onChange={handleTextChange}
            placeholder="Notification text"
            inputClasses="name-input text-box--area"
            message={(
              <div className="textarea-num">
                {text?.length}
                /148
              </div>
            )}
          />
        </div>
      </div>
      {/* Target */}
      <div className="box">
        <h6 className="box-title">Target</h6>
        <RadioButtonGroup
          options={TARGET_OPTIONS}
          selectedOption={target}
          setSelectedOption={handleChangeTarget}
          classes="list"
          labelClasses="list-item-custom"
          checkmarkClasses="checkmark-custom"
        />
        {target.title === TARGET_OPTIONS[1].title ? (
          <div className="segments">
            {customerSegment.map((c, index) => (
              <Checkbox
                key={index}
                classes=""
                name={c.title}
                checked={c.checked}
                onChange={() => dispatchCustomerSegment({
                  type: c.title,
                  payload: !c.checked,
                })}
                label={c.title}
                labelClasses="item-label"
                classes="item-checkbox"
                id={index}
              />
            ))}
            {/* <p className="info-note">
              <InfoIcon />
              <span>This campaign targets 6.8K users.</span>
            </p> */}
          </div>
        ) : null}
      </div>
      {/* Sending time */}
      <div className="box">
        <h6 className="box-title">Sending time</h6>
        <div className="flex-row flex-row--start">
          <Dropdown
            label={(
              <BadgeLabel
                label={
                  SENDING_TIME_OPTIONS.find(
                    (t) => t.title === sendingTimeTypeTitle,
                  )?.element
                }
                trailingIcon={<DropdownIcon />}
                classes="active-date-label"
              />
            )}
            options={SENDING_TIME_OPTIONS}
            onChange={setSendingTimeTypeTitle}
            selected={null}
            noArrow
            btnClass="active-date-label-btn"
            btnOpenClass="active-date-label-btn active-date-label-btn-open"
            listClass="active-date-label-list"
            optionClass="active-date-label-list-item"
          />
          {sendingTimeTypeTitle === SENDING_TIME_OPTIONS[1].title ? (
            <>
              <DatePickerComponent
                selectedDate={date}
                setSelectedDate={setDate}
              />
              <TimePicker time={time} setTime={setTime} />
            </>
          ) : null}
        </div>
      </div>
      {/* Buttons */}
      <div className="box--bottom">
        {isEdit() ? (
          <Button
            onClick={handleDelete}
            label="Delete"
            type="neutral"
            classes="btn btn-delete"
          />
        ) : null}
        <div className="btns-right">
          <Button
            onClick={handleCancel}
            label="Cancel"
            type="neutral"
            classes="btn btn-cancel"
          />
          {isEdit() ? (
            <Button
              onClick={handleSave}
              label="Save notification"
              type="filled"
              classes="btn btn-create"
              loading={saveBtnLoading}
              disabled={!checkIsValid()}
            />
          ) : (
            <Button
              onClick={handleSave}
              label="Create notification"
              type="filled"
              classes="btn btn-create"
              loading={saveBtnLoading}
              disabled={!checkIsValid()}
            />
          )}
        </div>
      </div>
    </StyledWrapper>
  );
}

export default Form;
