import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import {
  Alert, Button, Flex, Input, Modal, Row, Space, Typography,
} from 'antd';
import { addAddressToCustomer } from 'api/customers';
import { getParentByLocation } from 'api/parents';
import SkeletonLoading from 'components/common/SkeletonLoading';
import Map from 'components/Map';
import { AdminContext } from 'constants/contexts';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import useConfig from 'hooks/useConfig';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { captureException } from 'utils/errors';

const STEPS = {
  GET_LOCATION: 'GET_LOCATION',
  GET_INFO: 'GET_INFO',
};

function AddAddressModal({
  showAddAddressModal,
  setShowAddAddressModal,
  onSave,
}) {
  const { defaultLat, defaultLong } = useConfig('general');
  const { order } = useContext(CallInOrderContext);
  const [step, setStep] = useState(STEPS.GET_LOCATION);
  const [address, setAddress] = useState({ label: null, details: null, deliveryInstructions: null });
  const [loading, setLoading] = useState(false);
  const [saveAddressLoading, setSaveAddressLoading] = useState(false);
  const [location, setLocation] = useState({ lat: defaultLat, lng: defaultLong });
  const [currentLocationIconDisabled, setCurrentLocationIconDisabled] =
    useState(false);

  const { organization } = useContext(AdminContext);
  const [outOfZone, setOutOfZone] = useState(false);

  useEffect(() => {
    setStep(STEPS.GET_LOCATION);
  }, [showAddAddressModal]);

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator?.permissions &&
        navigator.permissions
          .query({ name: 'geolocation' })
          .then((result) => {
            if (result.state === 'granted') {
            } else if (result.state === 'prompt') {
            } else if (result.state === 'denied') {
              setCurrentLocationIconDisabled(true);
            }
            result.onchange = function () {
            };
          });
    } else {
    }
  }, []);

  const cleanupState = () => {
    setStep(STEPS.GET_LOCATION);
    setAddress({ label: null, details: null, deliveryInstructions: null });
    setLoading(false);
    setSaveAddressLoading(false);
    setLocation({ lat: defaultLat, lng: defaultLong });
    setCurrentLocationIconDisabled(false);
  };
  const confirmLocation = () => {
    setStep(STEPS.GET_INFO);
  };

  const updateAddress = () => {
    const data = {
      title: address.label,
      address: location?.text,
      latitude: location?.lat,
      longitude: location?.lng,
      details: address.details,
      delivery_instructions: address.deliveryInstructions,
      customer: order.userId,
    };

    setSaveAddressLoading(true);
    addAddressToCustomer(data)
      .then((res) => {
        onSave && onSave();
        setSaveAddressLoading(false);
        setShowAddAddressModal(false);
        cleanupState();
      })
      .catch((e) => {
        captureException(e);
        setSaveAddressLoading(false);
      });
  };

  useEffect(() => {
    const checkAddressInParents = () => {
      if (location) {
        if ((location?.lat, location?.lng)) {
          setLoading(true);
          getParentByLocation({
            lat: location?.lat,
            lng: location?.lng,
          }).then((res) => {
            setLoading(false);
            setOutOfZone(res?.data?.length === 0);
          })
            .catch((e) => {
              captureException(e);
              setLoading(false);
            });
        }
      }
    };

    checkAddressInParents();
  }, [location]);

  const isDisable = useMemo(() => {
    if (step === STEPS.GET_LOCATION) {
      return outOfZone || loading || !location?.lat || !location?.lng || !location?.text;
    }
    return saveAddressLoading;
  }, [location, outOfZone, saveAddressLoading, loading]);

  const createLabel = () => (step === STEPS.GET_LOCATION ? 'Confirm location' : 'Save address');

  const handleBtnClick = () => (step === STEPS.GET_LOCATION ? confirmLocation : updateAddress);

  if (!showAddAddressModal) {
    return null;
  }

  function handleUpdateAddressDetail(payload) {
    setAddress((adr) => ({ ...adr, ...payload }));
  }

  return (
    <Modal
      open={showAddAddressModal}
      title={
        step === STEPS.GET_LOCATION ? (
          'Choose Location'
        ) : (
          <Space>
            {step === STEPS.GET_INFO && <ArrowLeftOutlined onClick={() => setStep(STEPS.GET_LOCATION)} />}
            <Typography.Text>Add Address</Typography.Text>
          </Space>
        )
      }
      onCancel={() => {
        setShowAddAddressModal(false);
        cleanupState();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setShowAddAddressModal(false);
            cleanupState();
          }}
        >
          Cancel
        </Button>,
        <Button
          onClick={handleBtnClick()}
          type="primary"
          disabled={isDisable}
        >
          {createLabel()}
        </Button>,
      ]}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {step === STEPS.GET_LOCATION ? (
          <>
            <Map
              center={location}
              onChange={setLocation}
            >
              <Map.FixedMarker />
              <Map.SearchInput onChange={(event) => setLocation(event)} />
            </Map>

            <Space>
              {loading ? (
                <SkeletonLoading count={1} width="150px" />
              ) : outOfZone ? (
                <Alert
                  message={`${organization.name} does not currently operate in this location. Follow
                  along as we launch in new cities.`}
                  type="warning"
                />
              ) : (
                <Typography.Text type="c-m" classes="content-add-info">
                  {location?.text}
                </Typography.Text>
              )}

            </Space>
          </>
        ) : (
          <>
            <div style={{ pointerEvents: 'none' }}>
              <Map
                center={location}
                height={189}
                zoomControl={false}
                fullscreenControl={false}
              >
                <Map.FixedMarker />
              </Map>
            </div>
            <Flex justify="space-between" align="center">
              <Typography.Text type="c-m" classes="content-add-info">
                {location?.text}
              </Typography.Text>
              <Button type="text" icon={<EditOutlined />} onClick={() => setStep(STEPS.GET_LOCATION)}>Edit</Button>
            </Flex>

            <Row>Address label</Row>
            <Row>
              <Input
                className="details-input"
                type="text"
                id="label"
                value={address.label}
                onChange={({ target: { value } }) => handleUpdateAddressDetail({ label: value })}
                placeholder="ex. Home"
              />
            </Row>
            <Row>Details</Row>
            <Row>
              <Input
                className="details-input"
                type="text"
                id="detail"
                value={address.details}
                onChange={({ target: { value } }) => handleUpdateAddressDetail({ details: value })}
                placeholder="Apt, floor, suite, etc"
              />
            </Row>
            <Row>Delivery Instructions</Row>
            <Row>
              <Input
                className="details-input"
                type="text"
                id="code"
                value={address.deliveryInstructions}
                onChange={({ target: { value } }) => handleUpdateAddressDetail({ deliveryInstructions: value })}
                placeholder="Example: Please knock instead of using the doorbell"
              />
            </Row>
          </>
        )}
      </Space>
    </Modal>
  );
}

export default AddAddressModal;
