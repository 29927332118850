export const sidebar = {
  position: 'fixed',
  background: '#F2F7F4',
  overflow: 'hidden auto',
  height: 'calc(100vh - 48px)',
  left: 0,
  top: 0,
  bottom: 0,
  borderRight: '1px solid rgba(16, 17, 18, 0.1)',
};

export const sidebarTrigger = (collapsed) => ({
  background: '#F2F7F4',
  borderTop: '1px solid rgba(16, 17, 18, 0.1)',
  paddingLeft: collapsed ? 0 : '20px',
  borderRight: '1px solid rgba(16, 17, 18, 0.1)',
});
export const sidebarTriggerIcon = {
  fontSize: '16px',
};

export const sidebarLogo = {
  paddingLeft: '20px',
  paddingBottom: '32px',
};

export const sidebarItemsContainer = {
  background: '#F2F7F4',
};

export const sidebarTopContainer = {
  padding: '24px 0',
  height: '96vh',
};

export const organizationSelector = {
  paddingLeft: '20px',
  paddingRight: '5px',
  marginBottom: '24px',
  display: 'flex',
  alignItems: 'center',
  minHeight: '32px',
};

export const organizationSelectorIconContainer = (collapsed) => ({
  padding: '6px 7px 5px',
  background: '#fff',
  borderRadius: '50%',
  boxShadow: '0px 2px 5px rgba(60, 66, 87, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.12)',
  marginLeft: collapsed ? 4 : 0,
});

export const organizationSelectorIcon = {
  fontSize: '18px',
  color: 'black',
};
