import http from 'services/http';

export const resetPassword = (data) => http.post('user/rest-auth/password/reset/', data, {
  headers: {
    Authorization: '',
  },
}).then((res) => res.data);

export const resetPasswordConfirm = (data) => http.post('user/rest-auth/password/reset/confirm/', data, {
  headers: {
    Authorization: '',
  },
}).then((res) => res.data);

export const logout = () => http.post('v1/user/rest-auth/logout/', {}).then((res) => res.data);

export const fetchMyPermissions = () => http.get('v1/user/permissions/').then((res) => res.data);

export const signUp = (data) => http.put('v1/user/set-password/', data, {
  headers: {
    Authorization: '',
  },
}).then((res) => res.data);
