import http from 'services/http';
import { generateQuery } from 'utils';

export const getAllNotifications =
  (searchKey, page, status) => {
    const query = generateQuery([
      searchKey ? `search=${searchKey}` : '',
      page > 1 ? `page=${page}` : '',
      status ? `status=${status}` : '',
    ]);
    return http.get(`v1/organizations/{{organizationId}}/notifications/${query}`).then((res) => res.data);
  };

export const getNotificationById = (id) => http.get(`v1/organizations/{{organizationId}}/notifications/${id}`).then((res) => res.data);

export const createNotification = (data) => http.post('v1/organizations/{{organizationId}}/notifications/', data);

export const editNotification = ({ id, data }) => http.put(`v1/organizations/{{organizationId}}/notifications/${id}`, data);

export const deleteNotificationById = (id) => http.delete(`v1/organizations/{{organizationId}}/notifications/${id}`);
