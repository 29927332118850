import styled from 'styled-components';
import { Colors } from 'assets/colors';

export const StyledWrapper = styled.div`
  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
  }

  .tab {
    padding: 11px 24px;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;

    &--active {
      color: ${Colors.primary1};
      box-shadow: inset 0px -2px 0px ${Colors.primary1};
      font-weight: 700;
    }

    &--inactive {
      color: #707070;
      font-weight: 600;
    }
  }
`;
