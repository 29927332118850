import React, { useState } from 'react';

import { captureException } from 'utils/errors';
import SwitchInput from 'components/SwitchInput';
import http from 'services/http';
import { App } from 'antd';

function BannedSwitch({ customer, onChange }) {
  const [loading, setLoading] = useState(false);
  const { modal } = App.useApp();

  function handleChange(value) {
    modal.confirm({
      title: 'Ban User from Ordering?',
      content: 'Are you sure you want to ban this user from placing orders?',
      okText: 'confirm',
      onOk() {
        setLoading(true);
        return http.patch(`/v1/organizations/{{organizationId}}/customers/${customer.id}/`, { is_banned: value })
          .then(({ data: { data } }) => onChange(data.is_banned))
          .catch(captureException)
          .finally(() => setLoading(false));
      },
    });
  }

  return (
    <SwitchInput
      label="Banned"
      loading={loading}
      checked={customer?.is_banned ?? false}
      unCheckedChildren={null}
      onChange={handleChange}
      description={'Toggles the customer\'s ability to place orders. When switched "On," the customer will be temporarily banned from ordering.'}
    />
  );
}

export default BannedSwitch;
