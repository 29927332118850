import React, { useState } from 'react';

import { captureException } from 'utils/errors';
import SwitchInput from 'components/SwitchInput';
import http from 'services/http';

function TaxFreeSwitch({ customer, onChange }) {
  const [loading, setLoading] = useState(false);

  function handleChange(value) {
    setLoading(true);
    return http.patch(`/v1/organizations/{{organizationId}}/customers/${customer.id}/`, { is_tax_exempt: value })
      .then(({ data: { data } }) => onChange(data.is_tax_exempt))
      .catch(captureException)
      .finally(() => setLoading(false));
  }

  return (
    <SwitchInput
      label="Tax-Free Orders"
      loading={loading}
      checked={customer?.is_tax_exempt ?? false}
      unCheckedChildren={null}
      onChange={handleChange}
      description="Allow this customer to place tax-free orders. Enabling this option supports NGO activities by exempting eligible orders from tax."
    />
  );
}

export default TaxFreeSwitch;
