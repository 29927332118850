import React, { useState } from 'react';
import {
  Button,
} from 'antd';

import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import { PERMISSIONS } from 'constants/permissions';
import FormModal from './FormModal';

function Owner({
  owner,
  setOwner,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);

  if (owner) {
    return (
      <>
        <FormModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          setOwner={setOwner}
          ownerEmail={owner.email}
        />
        <div className="details-cell-title">Owner Email</div>
        <div className="details-cell-data">
          {owner.email}
          {
            isAdmin && (
              <Button
                type="link"
                size="small"
                className="ml-1"
                onClick={() => setIsModalOpen(true)}
              >
                Edit
              </Button>
            )
          }
        </div>
      </>
    );
  }
  return isAdmin && (
    <>
      <FormModal
        open={isModalOpen}
        setOwner={setOwner}
        onClose={() => setIsModalOpen(false)}
      />
      <Button className="details-cell" onClick={() => setIsModalOpen(true)}>
        Add merchant owner
      </Button>
    </>
  );
}

export default Owner;
