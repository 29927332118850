import React from 'react';

import DebounceSelect from 'components/Select/DebounceSelect';
import http, { getHttpErrorMessage } from 'services/http';
import { generateURLWithParams } from 'utils';
import { App } from 'antd';
import { captureException } from 'utils/errors';

function TaxesInput(props) {
  const { message } = App.useApp();
  const fetchTags = (search) => http(generateURLWithParams({
    url: 'v1/tax/active-tax/',
    params: { search, query: '{id,title}' },

  })).then(({ data }) => [...data.results, ...props.selectedTaxes].map(({ title, id }) => ({ value: id, label: title })))
    .catch((e) => {
      captureException(e);
      message.error(getHttpErrorMessage(e));
    });

  return (
    <DebounceSelect
      mode="multiple"
      allowClear
      placeholder="Search taxes"
      fetchOptions={fetchTags}
      showSearch
      style={{ width: '100%', height: 40, marginTop: 8 }}
      labelInValue={false}
      {...props}
    />

  );
}

export default TaxesInput;
