import {
  Button, Col, Flex, Input, Row, Skeleton, Typography,
} from 'antd';
import Layout from 'components/Layout';
import Map from 'components/Map';
import { AdminContext } from 'constants/contexts';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { captureException } from 'utils/errors';
import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import { editAddress as editAddressApi } from '../../api/address';
import { getCustomerById } from '../../api/customers';
import { getData } from '../../utils';
import { StyledWrapper } from './styles';

function EditAddress() {
  const history = useHistory();
  const { id, addressId } = useParams();
  const { organizationId } = useContext(AdminContext);
  const [address, setAddress] = useState({ title: null, details: null, deliveryInstructions: null });
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    if (id && organizationId) {
      setLoading(true);
      getCustomerById(id).then((res) => {
        const data = getData(res)?.customer_addresses?.find(
          (a) => a?.id == addressId,
        );
        setAddress({ ...data, deliveryInstructions: data.delivery_instructions });
        setLocation({ ...data?.location, text: data.address });
        setLoading(false);
      }).catch((e) => {
        captureException(e);
        setLoading(false);
      });
    }
  }, [id, organizationId]);

  useEffect(() => {
    if (location?.place_name) {
      setAddress(location?.place_name);
    }
  }, [location]);

  const handleSave = () => {
    if (
      location.text &&
      (location?.lat || location?.center[0]) &&
      (location?.lng || location?.center[1]) &&
      addressId
    ) {
      setSaveLoading(true);
      editAddressApi({
        id: addressId,
        title: address.title,
        details: address.details,
        delivery_instructions: address.deliveryInstructions,
        address: location.text,
        location: {
          lat: location?.lat || location?.center[0],
          lng: location?.lng || location?.center[1],
        },
      }).then(() => {
        setSaveLoading(false);
        history.goBack();
      })
        .catch((e) => {
          captureException(e);
          setSaveLoading(true);
        });
    }
  };

  function handleUpdateAddressDetail(payload) {
    setAddress((adr) => ({ ...adr, ...payload }));
  }

  return (
    <Layout header={(
      <Layout.Header
        title="Edit address"
        backTitle="Customer Detail"
        backPath={`/console/customers/${id}`}
      />
    )}
    >
      <StyledWrapper>
        {/* Box */}
        <div className="box">
          {loading && <Skeleton active />}
          {!loading && (
            <Row style={{ width: '100%', marginBottom: 16 }} gutter={16}>
              <Col xs={24} md={9} className="mb-4">
                <Flex vertical gap={8}>
                  <Typography.Text>Address label</Typography.Text>
                  <Input
                    className="details-input"
                    type="text"
                    id="title"
                    value={address.title}
                    onChange={({ target: { value } }) => handleUpdateAddressDetail({ title: value })}
                    placeholder="ex. Home"
                  />
                  <Typography.Text>Details</Typography.Text>
                  <Input
                    className="details-input"
                    type="text"
                    id="detail"
                    value={address.details}
                    onChange={({ target: { value } }) => handleUpdateAddressDetail({ details: value })}
                    placeholder="Apt, floor, suite, etc"
                  />
                  <Typography.Text>Delivery Instructions</Typography.Text>
                  <Input
                    className="details-input"
                    type="text"
                    id="code"
                    value={address.deliveryInstructions}
                    onChange={({ target: { value } }) => handleUpdateAddressDetail({ deliveryInstructions: value })}
                    placeholder="Example: Please knock instead of using the doorbell"
                  />
                </Flex>
              </Col>
              <Col xs={24} md={15}>
                <Map
                  center={location}
                  onChange={setLocation}
                  height={380}
                >
                  <Map.FixedMarker />
                  <Map.SearchInput onChange={(event) => setLocation(event)} />
                </Map>
                <Typography.Text>
                  {location?.text || address.address}
                </Typography.Text>
              </Col>
            </Row>
          )}
          <Flex justify="flex-end" gap={8} className="box-bottom">
            <Button onClick={history.goBack} disabled={saveLoading}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleSave}
              loading={saveLoading}
              disabled={
                !location?.text ||
                !(location?.lat || (location?.center && location?.center[0])) ||
                !(location?.lng || (location?.center && location?.center[1])) ||
                !addressId
              }
            >
              Save
            </Button>
          </Flex>
        </div>
      </StyledWrapper>
    </Layout>
  );
}

export default withGuard(EditAddress, [PERMISSIONS.CUSTOMERS], { redirect: true });
