import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';
import Edit from './Edit';
import Create from './Create';

function Location() {
  return (
    <>
      <Route path="/console/locations/" component={List} />
      <Switch>
        <Route path="/console/locations/create" exact component={Create} />
        <Route path="/console/locations/:id" exact component={Edit} />
      </Switch>
    </>
  );
}

export default Location;
