import React, { useState } from 'react';
import { Form as BaseForm, message } from 'antd';

import { useHistory, useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import ConfirmModal from './ConfirmModal';
import Form from './Form';
import { createTransaction } from './requests';

function AdjustBalance() {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [form] = BaseForm.useForm();
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  function handleSubmit() {
    setLoading(true);
    const values = form.getFieldsValue();
    createTransaction(id, {
      ...values,
      amount: Math.round(values.amount * 100),
    }).then(() => {
      message.success('Transaction created successfully!');
      history.push(`/console/customers/${id}`);
    }).catch((e) => {
      captureException(e);
      message.error(e?.response?.data?.title ?? 'Some thing went wrong!');
    }).finally(() => setLoading(false));
  }

  return (
    <>
      <Form
        form={form}
        onSubmit={() => setConfirmOpen(true)}
        loading={loading}
      />
      <ConfirmModal
        data={form.getFieldsValue()}
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleSubmit}
      />
    </>

  );
}

export default AdjustBalance;
