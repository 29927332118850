import React, { useContext, useEffect } from 'react';
import { ReactComponent as SolidCloseIcon } from 'assets/image/solidClose.svg';
import { createPortal } from 'react-dom';
import { StyledModalWrapper } from './styles';
import { ModalContext } from '../../context/modalContext';

function GeneralModal({ ...props }) {
  const {
    modal, handleModal, modalContent, hideCloseBtn, classes,
  } =
    useContext(ModalContext);

  if (modal) {
    return createPortal(
      <StyledModalWrapper onClick={() => handleModal()}>
        <div onClick={(e) => e.stopPropagation()} className="modal-general">
          {hideCloseBtn ? null : (
            <div onClick={() => handleModal()} className={`close ${classes}`}>
              <SolidCloseIcon />
            </div>
          )}
          {modalContent}
        </div>
      </StyledModalWrapper>,
      document.getElementById('modal-root'),
    );
  }
  return null;
}

export default GeneralModal;
