import http from 'services/http';
import { generateURLWithParams } from 'utils';

export const fetchAccessibleRoles = () => http.get('v1/user/organizations/roles/').then((res) => res.data);

export const fetchUsersPermissions = (params) => {
  const url = generateURLWithParams('v1/user/organization/{{organizationId}}/users-permissions/', {
    ...params,
  });
  return http.get(url).then((res) => res.data);
};

export const createNewUserPermission = ({
  email, roles, parents, access_all_parents, restaurants_administrated,
}) => http.post('v1/user/organization/{{organizationId}}/users-permissions/', {
  email,
  roles,
  parents,
  access_all_parents,
  restaurants_administrated,
}).then((res) => res.data);

export const getUserPermissions = (userId) => http.get(`v1/user/organization/{{organizationId}}/users-permissions/${userId}/`).then((res) => res.data);

export const updateUserPermissions = ({
  userId, email, roles, parents, access_all_parents, restaurants_administrated,
}) => http.put(`v1/user/organization/{{organizationId}}/users-permissions/${userId}/`, {
  email,
  roles,
  parents,
  access_all_parents,
  restaurants_administrated,
}).then((res) => res.data);

export const deleteUserPermissions = (userId) => http.delete(`v1/user/organization/{{organizationId}}/users-permissions/${userId}/`).then((res) => res.data);
