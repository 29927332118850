import { Form, Input } from 'antd';
import React from 'react';
import FormItemLabel from './FormItemLabel';
import { formSection } from './styles';

function SelectUser({}) {
  return (
    <div style={formSection}>
      <Form.Item
        label={<FormItemLabel>Enter user email address</FormItemLabel>}
        name="userEmailAddress"
        rules={[
          {
            type: 'email',
            message: 'Please input a correct email address!',
          },
          {
            required: true,
            message: 'Please input user email!',
          },
        ]}
      >
        <Input placeholder="user@mail.com" />
      </Form.Item>
    </div>
  );
}

export default SelectUser;
