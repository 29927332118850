import http from 'services/http';

export const createModifier = (modifier) => http.post('v1/modifiers/', modifier).then((res) => res.data);

export const editModifier = ({
  modifierId,
  modifier,
}) => http.put(`v1/modifiers/${modifierId}/`, modifier).then((res) => res.data);

export const duplicateModifier = (modifierId) => http.post(`v1/modifiers/${modifierId}/duplicate/`).then((res) => res.data);
