import styled from 'styled-components';
import { Colors } from 'assets/colors';

export const StyledWrapper = styled.div`
  position: relative;

  .search-input {
    width: 100%;
    height: 52px;
    border: 1px solid #d8dce2;
    border-top: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-right: none;
    border-left: none;

    .input {
      border: none;
      width: 100%;
      height: 100%;
      padding-left: 82px;
      padding-top: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      transition: all 1s ease;
      color: ${Colors.textPrimary1};

      &::placeholder {
        color: #7b7b7b;
      }

      &:focus,
      &:focus-visible {
        outline: none;
        background: #f7f9fa;
        transition: all 1s ease;

        + .label {
          color: ${Colors.primary1};
        }
      }
    }

    .label {
      position: absolute;
      left: 56px;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .title-row {
    padding-left: 56px;
    padding-right: 56px;
    margin-bottom: 40px;
    display: block;

    .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      color: ${Colors.textPrimary1} !important;
      margin-right: 27px;
      margin-bottom: 0;
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -48px;
    margin-right: 0;
    @media (max-width: 575.98px) {
      margin-top: 0;
    }
  }

  .tabs-box {
    border-bottom: 1px solid #e7e8e9;
    margin-bottom: 12px;
  }

  .sort {
    padding: 0 !important;
    width: 140px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #131b1f;
    justify-content: flex-start !important;
    position: relative;
    top: unset !important;

    .icon--lead {
      color: #898d8f;
      width: 43px;
    }

    &-list {
      width: 185px !important;

      &-item {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #131b1f;
      }
    }

    &-btn {
      width: 185px !important;
      padding: 6px;
      line-height: 18px;
    }
  }
`;
