import { getMerchantById } from 'api/merchants';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  App,
  Button,
  Flex,
  Form, Input,
  message,
  Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';

import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';

import AmountInput from 'components/AmountInput';
import Togglable from 'components/TogglableInput';
import http, { getHttpErrorMessage } from 'services/http';
import { useDebouncedCallback } from 'use-debounce';
import { formatAmount, generateURLWithParams } from 'utils';
import { captureException } from 'utils/errors';
import ItemsInput from 'components/ItemsInput';
import SchedulingInput from './SchedulingInput';
import TipInput from './TipInput';

function OrderForm({
  data,
  loading,
}) {
  const [form] = Form.useForm();
  const [validating, setValidating] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [merchant, setMerchant] = useState(false);
  const validateVersionRef = useRef(0);
  const [errors, setErrors] = useState([]);
  const { modal } = App.useApp([]);
  const [messageApi, contextHolder] = message.useMessage();
  const latestOrderDataRef = useRef();

  useEffect(() => {
    if (!data) {
      return;
    }
    form.setFieldsValue(deserializeData(data));
  }, [data, form]);

  useEffect(() => {
    if (data?.merchant?.id && !merchant) {
      getMerchantById(data.merchant.id)
        .then(({ data }) => setMerchant(data))
        .catch(captureException);
    }
  }, [data]);

  const history = useHistory();

  function submit({ values, dryRun, ignorePaymentErrors }) {
    return http.put(generateURLWithParams(
      `/v1/orders/${data.id}/edit/`,
      { dry_run: dryRun, ignore_payment_errors: ignorePaymentErrors },
    ), serializeData(values));
  }

  const validate = useDebouncedCallback(() => {
    const values = form.getFieldsValue();
    setValidating(true);
    validateVersionRef.current++;
    const validateVersion = validateVersionRef.current;
    submit({ dryRun: true, ignorePaymentErrors: false, values })
      .then((res) => {
        if (validateVersionRef.current !== validateVersion) {
          return;
        }
        setErrors([]);
        form.setFieldsValue(deserializeData(res.data));
        latestOrderDataRef.current = res.data;
      })
      .catch((e) => {
        if (e?.response?.data?.errors) {
          setErrors(formatErrors(e.response.data.errors));
        } else {
          messageApi.error({
            type: 'error',
            content: getHttpErrorMessage(e),
            style: {
              maxWidth: '60%',
              margin: 'auto',
            },
          });
          captureException(e);
        }
      })
      .finally(() => {
        setValidating(false);
      });
  }, 2000);

  function handleSubmit() {
    setSubmitting(true);
    const order = latestOrderDataRef.current;
    const financialInformation = order ? (
      <Flex vertical>
        <Typography.Title level={5}>Order summary</Typography.Title>
        {
          [
            ['Subtotal', formatAmount(order.food_value)],
            ['Delivery Fee', formatAmount(order.delivery_fare)],
            ['Service Fee', formatAmount(order.service_fee)],
            ['Tip', formatAmount(order.tip)],
            ['Tax', formatAmount(order.order_nets?.total_tax)],
            ['Adjustment Amount', formatAmount(order.adjustment_amount)],
          ].map(([label, value]) => (
            <Flex justify="space-between">
              <Typography.Text type="secondary">{label}</Typography.Text>
              <Typography.Text>{value}</Typography.Text>
            </Flex>
          ))
        }
      </Flex>
    ) : null;
    const confirmContent = (
      <>
        <Typography.Text>
          After confirming, these changes will be applied to the order. Are you sure you want to proceed?
        </Typography.Text>
        {financialInformation}
        {
          Boolean(latestOrderDataRef.current) && (
            <Flex justify="space-between">
              <Typography.Text strong>Order's total change</Typography.Text>
              <Typography.Text strong>
                {data.value}
                {' → '}
                {latestOrderDataRef.current.value}
              </Typography.Text>
            </Flex>
          )
        }
      </>
    );
    const returnToOrder = () => history.push(`/console/orders/${data.id}`);
    const turnOffLoading = () => setSubmitting(false);
    modal.confirm({
      icon: null,
      title: `Confirm order #${data.id} edit`,
      styles: {

      },
      content: (
        <Flex vertical gap={16} className="w-full">{confirmContent}</Flex>
      ),
      onCancel: turnOffLoading,
      onOk: () => submit({ values: form.getFieldsValue(), dryRun: false, ignorePaymentErrors: false })
        .then(() => {
          returnToOrder();
          turnOffLoading();
        })
        .catch((e) => {
          if (e?.response?.data?.title === 'PayByStripeCardRequiresActionException') {
            modal.confirm({
              title: `Confirm order #${data.id} edit`,
              content: (
                <Flex vertical gap={16} className="w-full">
                  {confirmContent}
                  <Alert type="warning" message="If the total amount changes, you must collect the difference from the user. Thank you." />
                </Flex>
              ),
              onCancel: turnOffLoading,
              onOk: () => submit({ values: form.getFieldsValue(), dryRun: false, ignorePaymentErrors: true })
                .then(() => {
                  returnToOrder();
                  turnOffLoading();
                })
                .catch((e) => {
                  messageApi.error({
                    type: 'error',
                    content: getHttpErrorMessage(e),
                    style: {
                      maxWidth: '60%',
                      margin: 'auto',
                    },
                  });
                  turnOffLoading();
                }),
            });
          } else {
            turnOffLoading();
            messageApi.error({
              type: 'error',
              content: getHttpErrorMessage(e),
              style: {
                maxWidth: '60%',
                margin: 'auto',
              },
            });
          }
        }),
    });
  }

  return (
    <FormLayout>
      {contextHolder}
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        onFieldsChange={(changes) => {
          if (!changes.every((change) => ['admin_note', 'delivery_message'].includes(change.name[0]))) {
            validate();
          }
          console.log('onFieldsChange => ', form.getFieldsValue());
        }}
      >
        {!loading && !data?.is_editable && (
          <FormLayout.Section className="mb-4" divider={false}>
            <Alert type="error" description="This order is not editable!" />
          </FormLayout.Section>
        )}
        {/* <FormLayout.Section title="General info" divider={false}>

          <FormItem fullWidth name="user_id" label="Select customer">
            <SelectCustomerInput />
          </FormItem>
          <FormItem name="has_delivery" label="Order type">
            <Radio.Group>
              <Flex gap={8}>
                <Radio value={false}>Pick Up</Radio>
                <Radio value={true}>Delivery</Radio>
              </Flex>
            </Radio.Group>
          </FormItem>
          <FormItem noStyle shouldUpdate>
            {() => (
              <FormItem
                name="address"
                userId={form.getFieldValue('user_id')}
                hidden={!form.getFieldValue('has_delivery')}
                fullWidth
              >
                <CustomerAddressInput
                  userId={form.getFieldValue('user_id')}
                  // disabled={!form.getFieldValue('user_id')}
                />
              </FormItem>
            )}
          </FormItem>
        </FormLayout.Section>*/}
        {
          errors.length > 0 && (
            <FormLayout.Section className="mb-4" divider={false}>
              {errors.map((message) => <Alert showIcon key="message" message={`${message}`} type="error" />)}
            </FormLayout.Section>
          )
        }
        <FormLayout.Section divider={false}>
          <FormItem fullWidth name="scheduled_for" rules={[{ required: data?.is_catering, message: 'Please select a scheduled time' }]}>
            <SchedulingInput merchantId={data?.merchant?.id} isCatering={data?.is_catering} />
          </FormItem>
        </FormLayout.Section>
        <FormLayout.Section title="Items" divider={false}>
          <FormItem noStyle name="order_items">
            <ItemsInput
              merchant={merchant}
              loading={loading}
              validating={validating}
              isCatering={data?.is_catering}
            />
          </FormItem>
        </FormLayout.Section>
        <FormLayout.Section title="Adjustments details">
          <Togglable title="Custom delivery Fee">
            <FormItem name="custom_delivery_fee">
              <AmountInput />
            </FormItem>
          </Togglable>
          <Togglable title="Add adjustment">
            <FormItem name="adjustment_amount">
              <AmountInput />
            </FormItem>
          </Togglable>
          {data && (
            <Togglable title="Delivery Tip" defaultVisible={data?.tip || data?.tip_percentage}>
              <FormItem fullWidth name="tip">
                <TipInput />
              </FormItem>
            </Togglable>
          )}
        </FormLayout.Section>
        <FormLayout.Section>
          <FormItem
            name="admin_note"
            label="Self Note"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </FormItem>
          <FormItem name="delivery_message" label="Delivery Note">
            <Input.TextArea />
          </FormItem>
        </FormLayout.Section>
        <FormLayout.Footer gap={8}>
          <Button
            onClick={() => history.push(`/console/orders/${data?.id}`)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={submitting}
            disabled={
              errors.length > 0 ||
              submitting ||
              validating ||
              !data?.is_editable
            }
          >
            Confirm
          </Button>
        </FormLayout.Footer>
      </Form>
    </FormLayout>

  );
}

function serializeData({
  order_items,
  tip,
  ...data
}) {
  return {
    ...data,
    admin_note: data.admin_note,
    tip: tip.amount,
    tip_percentage: tip.percentage,
    items: order_items.map(({
      item_related, message, qty, size, order_options, real_price,
    }) => ({
      id: undefined,
      qty,
      size: size.position,
      message,
      item_related: item_related.id,
      real_price,
      options: order_options.map(({ modifier_option, ...orderOption }) => ({
        ...orderOption,
        modifier_option: modifier_option.id,
        id: undefined,
      })),
    })),
  };
}

function deserializeData({ ...data }) {
  return {
    ...data,
    custom_delivery_fee: data.delivery_fare,
    tip: {
      amount: data.tip,
      percentage: data.tip_percentage,
    },
    order_items: data.order_items.map((orderItem) => ({
      ...orderItem,
      real_price: Number(orderItem.real_price),
      size: { position: orderItem.size_number, name: orderItem.size, serving_size: orderItem.serving_size },
    })),
  };
}

function formatErrors(errors) {
  return errors.map((e) => traverse('', e));

  function traverse(parent, child) {
    if (typeof child === 'string') {
      return `${parent} > ${child}`;
    } if (Array.isArray(child)) {
      return traverse(parent, child[0]);
    } if (typeof child === 'object') {
      const key = Object.keys(child)[0];
      return traverse(`${parent} > ${key}`, child[key]);
    }
    return parent;
  }
}

export default OrderForm;
