import React, { useContext, useEffect, useState } from 'react';

import BadgeLabel from 'components/common/BadgeLabel';
import NewTabLink from 'components/common/NewTabLink';
import PaginateTable from 'components/common/PaginateTable';
import moment from 'moment';
import { priceTransformer } from 'utils';
import { useHistory } from 'react-router';
import { StyledTable } from './styles';

function Table({
  categoryTitle,
  loading,
  items,
  pagination,
  resetPaging,
  ...props
}) {
  const history = useHistory();
  const handleStatus = (row) => (
    <BadgeLabel
      label={row.status}
      classes={`status status--${row.status?.toLowerCase()}`}
    />
  );

  const handleFormatDate = (d) => (d ? moment(d)?.format('MMM D, hh:mm A') : ' - ');

  return (
    <StyledTable>
      {/* Table */}
      <PaginateTable
        loading={loading}
        headers={[
          { title: 'Name', width: '280px' },
          { title: 'Status', width: '164px' },
          { title: 'Last order', width: '204px' },
          { title: 'orders', width: '204px' },
          { title: 'spent', width: 'auto' },
        ]}
        dataArray={loading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : items}
        rowCreators={[
          (row, index) => (
            <div className="">
              {row?.id ? (
                <NewTabLink
                  label={row?.name || '-'}
                  link={`/console/customers/${row?.id}`}
                />
              ) : (
                '-'
              )}
            </div>
          ),
          (row, index) => <div className="">{handleStatus(row)}</div>,
          (row, index) => (
            <div className="">{handleFormatDate(row.last_order_date)}</div>
          ),
          (row, index) => <div className="strong">{row.orders_count}</div>,
          (row, index) => (
            <div className="strong">
              {priceTransformer(row.total_spent?.toFixed(2))}
            </div>
          ),
        ]}
        noRecord="There are no records to display."
        onClick={(row) => {
          history.push(`/console/customers/${row?.id}`);
        }}
        newRowClasses="new-row"
        dataPaging={pagination}
        tableStyle={{
          width: '400px',
          minWidth: '100%',
        }}
      />
    </StyledTable>
  );
}

export default Table;
