import {
  Flex, Skeleton, Space, Typography,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { getOrderType } from 'utils/order';

function GeneralDetail({ order, loading }) {
  if (!order || loading) {
    return <Skeleton paragraph={{ rows: 4 }} />;
  }
  return (
    <Flex vertical>
      <Typography.Title level={5} style={{ fontWeight: 800 }}>
        Catering order #
        {order.number}
        {' - '}
        {order.id}
      </Typography.Title>
      <Typography.Text style={{ color: '#00000073' }}>
        Placed on:
        {moment(order.timestamp).format('MMM D, LT')}
      </Typography.Text>

      <Flex vertical gap={8} style={{ marginTop: 16 }}>
        <Value label="Order type" value={getOrderType(order)} />
        <Value label="Headcount" value={`${order.headcount} people`} />
        <Value label="Merchant" value={order.merchant.name} />
        <Value label="Scheduled time" value={`${moment(order.scheduled_for).format('ddd MMM DD, LT')} - ${moment(order.scheduled_for).add(0.5, 'hour').format('LT')}`} />
        <Value label="Recipient address" value={order.address?.address || '-'} />
        {order.recipient_name && <Value label="Recipient name" value={`${order.recipient_name}  ${order.recipient_number}`} />}
        <Value label="Customer info" >
          <Typography.Text style={{ fontWeight: 800 }}>
            {order.is_gift
              ? (order.gift_sender_name || order.customer?.name)
              : order.customer?.name}
          </Typography.Text>
          <Typography.Text style={{ fontWeight: 800 }}>{order.customer?.phone}</Typography.Text>
          <Typography.Text style={{ fontWeight: 800 }}>{order.customer?.email}</Typography.Text>
        </Value>

      </Flex>
    </Flex>
  );
}

function Value({
  label, value, children, ...props
}) {
  return (
    <Space {...props}>
      <Typography.Text style={{ wordBreak: 'normal', textTransform: 'capitalize' }}>
        {label}
        :
      </Typography.Text>
      {children || <Typography.Text style={{ fontWeight: 800 }}>{value}</Typography.Text>}
    </Space>
  );
}

export default GeneralDetail;
