import InboxOutlined from '@ant-design/icons/InboxOutlined';
import {
  Button, Col, Flex, Input, Result, Row, Table, Typography,
} from 'antd';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import { getImage } from 'utils/image';
import React, { useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import { priceTransformer } from 'utils';
import { generateColumns } from 'utils/table';
import ItemPrice from '../MerchantItemsModal/ItemPrice';
import { getItemPrices, getItemTotalPrice } from '../MerchantItemsModal/ItemDetailModal/item';
import Quantity from '../Quantity';

const { TextArea } = Input;

function OrdersTable() {
  const { order: { items: orderItems, selectedMerchant }, setOrderDetails } = useContext(CallInOrderContext);
  const showSpecialNotes = selectedMerchant?.merchant?.config?.show_item_special_notes;
  const indexedItems = useMemo(() => orderItems.map((order, index) => ({ ...order, key: index })), [orderItems]);
  const handleRemoveItem = (row, rowIndex) => {
    const temp = [
      ...orderItems.filter((_, index) => index !== rowIndex),
    ];
    setOrderDetails({ items: [...temp] });
  };
  const handleChangeItemSpecialNote = (value, rowIndex) => {
    setOrderDetails({
      items: [
        ...orderItems.map((order, index) => {
          if (index === rowIndex) order.message = value;
          return order;
        }),
      ],
    });
  };

  const handleRemoveModifier = (mod, row, rowIndex) => {
    const temp = [...orderItems];
    const selectedModifier = temp[rowIndex]?.modifiers.filter(
      (m) => m?.id === mod.modifier,
    );
    if (
      selectedModifier.length &&
      selectedModifier[0]?.min_selections >
      temp[rowIndex].selection.modifiers.filter(
        (sm) => sm?.modifierTitle === mod?.modifierTitle,
      ).length -
      1
    ) {
      toast('You can not remove modifier.');
      return;
    }
    temp[rowIndex].selection.modifiers = [
      ...temp[rowIndex]?.selection?.modifiers?.filter(
        (m) => m?.id !== mod?.id,
      ),
    ];
    setOrderDetails({ items: temp });
  };

  const columns = [
    {
      title: 'name',
      key: 'name',
      width: '55%',
      render: (_, data) => (
        <div>
          {getImage({ data }) && (
            <img
              className="image"
              src={getImage({ data })}
              alt={data?.name || ''}
              width={45}
              height={45}
              style={{ border: '1px solid #DDE1E4', borderRadius: 5, marginRight: 4 }}
            />
          )}

          {data?.name || ' - '}
          {' '}
          {data?.sizeTitle ? `(${data?.sizeTitle})` : ''}
        </div>
      ),
    },
    {
      title: 'price',
      key: 'originalPrice',
      width: '12%',
      render: (_, data) => (
        <ItemPrice
          price={getItemPrices({ item: data })?.price}
          discountedPrice={getItemPrices({ item: data })?.discountedPrice}
          showPercentage={false}
          style={{ flexDirection: 'column' }}
        />
      ),
    },
    {
      title: 'quantity',
      key: 'qty',
      width: '35%',
      render: (_, data) => (
        <div className="flex flex-row justify-content-start">
          <span className="mr-4">x</span>
          <Quantity
            item={data}
            index={data?.id}
          />
          <span className="ml-4">
            =
            {' '}
            {(priceTransformer(getItemTotalPrice(data) * data?.qty))}
          </span>
        </div>
      ),
    },
    {
      title: '',
      width: '3%',
      render: (_, data, index) => <Button type="text" danger onClick={() => handleRemoveItem(data, index)}>Remove</Button>,
    },
  ];
  const normalizedColumns = useMemo(() => generateColumns(columns), [columns]);

  const renderRowDetail = (record, index) => (
    <Flex vertical className="m-4">
      {!!record.selection?.modifiers?.length && (
        <Flex vertical style={{ marginBottom: 12 }}>
          <Typography.Title level={5}>Modifiers</Typography.Title>
          {record.selection.modifiers.map((modifier) => (
            <Row style={{ alignItems: 'center' }}>
              <Col span={12}>
                <Typography.Text>
                  {modifier?.qty ? `${modifier?.qty}x ` : ''}
                  {modifier?.title}
                </Typography.Text>
              </Col>
              <Col span={4}>
                <Typography.Text>{priceTransformer(modifier?.price)}</Typography.Text>
              </Col>
              <Col span={8} style={{ textAlign: 'end' }}>
                {modifier?.modifier?.mandatory ||
                modifier?.modifier?.modifier_type === 4 ? null : (
                    <Button
                    onClick={() => handleRemoveModifier(modifier, record, index)}
                    type="text"
                    danger
                  >
                Remove
                  </Button>
                  )}
              </Col>
            </Row>
          ))}
        </Flex>
      )}

      {showSpecialNotes && (
        <Flex vertical>
          <Typography.Title level={5}>Special notes</Typography.Title>
          <TextArea
            value={record.message}
            style={{ width: 360 }}
            onChange={(e) => {
              handleChangeItemSpecialNote(e.target.value, index);
            }}
            rows={4}
            placeholder="Special notes"
            maxLength={100}
          />
        </Flex>
      )}

    </Flex>
  );
  return (
    <Table
      columns={normalizedColumns}
      dataSource={indexedItems}
      pagination={false}
      expandable={{
        expandedRowRender: renderRowDetail,
        rowExpandable: (record) => showSpecialNotes || !!record.selection?.modifiers?.length,
      }}
      style={{ marginTop: 16 }}
      locale={{
        emptyText: (<Result
          style={{
            marginTop: 20,
            filter: 'grayscale(1)',
            opacity: 0.3,
          }}
          icon={<InboxOutlined />}
          title="No data"
        />),
      }}
      scroll={{ x: 700 }}
    />
  );
}

export default OrdersTable;
