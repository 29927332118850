import moment from 'moment';
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';

dayjs.extend(dayjsDuration);

export const isDateToday = (date) => moment(date).diff(new Date(), 'days', true) <= 0;

export const filterTimesAfterNow = (list) => list.filter((t) => moment(moment(), 'hh:mm:ss').isSameOrBefore(moment(t.end, 'hh:mm:ss')));

export function isSameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth();
}

export const formatTime = (from_hour, duration) => {
  if (!duration) {
    return moment(from_hour, 'HH:mm:ss').format('hh:mm A');
  }
  return moment(from_hour, 'HH:mm:ss')
    .add(moment.duration(duration).hours(), 'hours')
    .format('hh:mm A');
};
