import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper } from './styles';

function Tabs({
  tabsList, classes, activeTab, setActiveTab, ...props
}) {
  return (
    <StyledWrapper>
      <div className={`tabs ${classes}`}>
        {tabsList.map((tab, index) => (tab.title ? (
          <div
            key={index}
            className={`tab tab--${
              activeTab.id === tab.id ? 'active' : 'inactive'
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.title}
          </div>
        ) : null))}
      </div>
    </StyledWrapper>
  );
}

Tabs.propTypes = {
  classes: PropTypes.string,
  tabsList: PropTypes.array,
  activeTab: PropTypes.any,
  setActiveTab: PropTypes.func,
};

export default Tabs;
