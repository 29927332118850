import http from 'services/http';

export const editAddress = async (
  {
    id,
    title,
    address,
    location,
    details,
    delivery_instructions,
  },
) => await http
  .patch(
    `v1/organizations/{{organizationId}}/addresses/${id}/`,
    {
      title,
      address,
      location,
      details,
      delivery_instructions,
    },
  )
  .then((res) => res.data);

export const fetchCustomerAddresses = (customerId) => http.get(`v1/organizations/{{organizationId}}/customers/${customerId}/addresses/`).then((res) => res.data);
