import {
  AutoComplete, Button, Flex, Space, Typography, Input,
} from 'antd';
import useIsMobile from 'hooks/useIsMobile';
import { debounce, orderBy } from 'lodash-es';
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { ModifierContext } from '../../../../context';
import { getNormalizedFilteredModifiers } from '../../../../utils';
import DraggableTable from '../../../../components/DraggableTable';

function Modifiers({ modifiers, onChangeModifiers }) {
  const { modifiers: allModifiers } = useContext(ModifierContext);
  const isMobile = useIsMobile();
  const [modifierOptions, setModifierOptions] = useState(getNormalizedFilteredModifiers({
    modifiers: allModifiers,
    excludeNested: true,
  }));
  const onAddModifier = (value, modifier) => {
    onChangeModifiers([...modifiers, modifier]);
  };
  const onRemoveItem = (e, id) => {
    e.preventDefault();
    const newModifiers = modifiers.filter((m) => m.id !== id);
    onChangeModifiers(newModifiers);
  };

  const columns = [
    {
      key: 'sort',
      width: 30,
      align: 'right',
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      editable: true,
    },
    {
      title: '',
      key: 'operation',
      width: 70,
      align: 'center',
      render: (_, record) => (
        <Space size={0}>
          <Button type="text" onClick={(e) => onRemoveItem(e, record.id)}>
            Remove
          </Button>
        </Space>
      ),
    },
  ];

  const sortedOptions = useMemo(() => {
    const indexedModifiers = modifiers?.map((mod) => ({ ...mod, key: mod.id })) || [];
    return orderBy(indexedModifiers, ['position'], ['asc']);
  }, [modifiers]);

  const reorderPositions = (items) => {
    const reorderedModifiers = items.map((item, index) => ({
      ...item,
      position: index + 1,
    }));
    onChangeModifiers(reorderedModifiers);
  };
  const debouncedChangeHandler = useCallback(debounce((searchTerm) => {
    setModifierOptions(getNormalizedFilteredModifiers({
      searchTerm,
      modifiers: allModifiers,
      excludeNested: true,
    }));
  }, 300), []);
  return (
    <>
      <Typography.Text strong>
        Modifiers
        <Typography.Text type="secondary"> (optional)</Typography.Text>
      </Typography.Text>
      <Flex vertical gap="middle" style={{ marginTop: 8 }}>
        <AutoComplete
          popupMatchSelectWidth={isMobile ? 290 : 350}
          style={{ width: isMobile ? '100%' : 395 }}
          options={modifierOptions}
          onSelect={onAddModifier}
          onSearch={debouncedChangeHandler}
        >
          <Input.Search placeholder="Search to add modifier" enterButton />
        </AutoComplete>

        {modifiers.length > 0 && (
          <DraggableTable
            columns={columns}
            dataSource={sortedOptions}
            setDataSource={reorderPositions}
            showHeader={false}
          />
        )}
      </Flex>
    </>
  );
}

export default Modifiers;
