import http from 'services/http';

export function fetchMerchants({ query }) {
  return http.get(`v1/organizations/{{organizationId}}/merchants/?search=${query}`)
    .then((res) => res.data);
}

export function createTransaction(customerId, data) {
  return http.post(`v1/billing/organizations/{{organizationId}}/customers/${customerId}/transactions/`, data)
    .then((res) => res.data);
}
