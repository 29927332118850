import { FOODHUB, OMNI_MIGRATION_DRIVER } from 'constants/migrationTypes';
import { formatAmount } from 'utils';

export const isDelivery = (type) => type === 2;

export const isOmni = (driver) => OMNI_MIGRATION_DRIVER.includes(driver);

export const isOmniPartnerDeprecated = (driver, isPartner) => isOmni(driver) && isPartner;
export const isOmniPartner = ({ migration_driver: driver, merchant: { is_partner: isPartner } = {} } = {}) => isOmni(driver) && isPartner;

export const isScheduled = (scheduledFor) => scheduledFor;

export const isSelfDelivery = ({ migration_driver: driver, order_source: source } = {}) => driver === FOODHUB && source?.toUpperCase() !== 'MERCHANT WEBSITE';

export const getItemValue = ({ value, migrationDriver }) => {
  switch (migrationDriver) {
  case 'DD':
    return value.replace('$', '');
  case 'FH':
    return value / 100;
  default:
    return value;
  }
};
