import React, { useEffect, useState } from 'react';
import Pagination from 'components/common/Pagination';

export default (onPageChange, loading) => {
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previous: null,
  });
  const [page, setPage] = useState(1);
  const paginationEl = (
    <Pagination
      paginationObj={pagination}
      page={page}
      setPage={setPage}
      loading={loading}
    />
  );

  useEffect(() => {
    onPageChange && onPageChange(page);
  }, [page]);

  return {
    page, setPage, pagination, setPagination, paginationEl,
  };
};
