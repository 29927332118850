import React from 'react';
import { InputNumber } from 'antd';

function AmountInput(props) {
  return (
    <InputNumber
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      prefix="$"
      min={0}
      {...props}
    />
  );
}

export default AmountInput;
