import { Tag } from 'antd';
import { PERMISSIONS } from 'constants/permissions';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import React, { useContext } from 'react';
import { ModalContext } from '../../../context/modalContext';
import { StyledWrapper } from './styles';

function Addresses({ addresses, customerId, goToEditPage }) {
  const { handleModal } = useContext(ModalContext);
  const hasCustomerAccess = useHasAnyOfPermissions([PERMISSIONS.CUSTOMERS]);
  return (
    <StyledWrapper>
      <div className="top">Addresses</div>
      {addresses?.map((address) => (
        <div key={address?.id} className="box">
          <div className="box-title flex justify-content-between">
            <span>{address?.title}</span>
            {address.is_deleted && <Tag color="error">Deleted</Tag>}
          </div>
          <div className="box-detail">{address?.address}</div>
          {hasCustomerAccess && (
            <div
              className="box-btn"
              onClick={() => {
                handleModal();
                goToEditPage(customerId, address);
              }}
            >
              Edit address
            </div>
          )}
        </div>
      ))}
    </StyledWrapper>
  );
}

export default Addresses;
