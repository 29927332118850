import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { ReactComponent as ClockIcon } from 'assets/image/clock.svg';
import moment from 'moment';
import { useOutsideClick } from '../../../hooks/useClickOutside';
import { StyledWrapper } from './styles';

function TimePicker({
  classes, time, setTime, ...props
}) {
  const splitTime = (tm) => {
    const h = tm.split(':')[0];
    const m = tm.split(':')[1].split(' ')[0];
    const t = tm.split(':')[1].split(' ')[1];
    return [h, m, t];
  };

  const [showTimePicker, setShowTimePicker] = useState(false);
  const [hours, setHours] = useState(
    time
      ? moment(time, 'hh:mm').format('hh')
      : splitTime(moment(new Date()).format('LT'))[0],
  );
  const [minutes, setMinutes] = useState(
    time
      ? moment(time, 'hh:mm').format('mm')
      : splitTime(moment(new Date()).format('LT'))[1],
  );
  const [timeSlot, setTimeSlot] = useState(
    time
      ? moment(time, 'hh:mm').format('A')
      : splitTime(moment(new Date()).format('LT'))[2],
  );
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => handleCloseTimePicker());

  const handleCloseTimePicker = () => {
    setShowTimePicker(false);
  };

  useEffect(() => {
    setTime(
      moment(`${hours}:${minutes} ${timeSlot}`, 'hh:mm A').format('HH:mm'),
    );
  }, [hours, minutes, timeSlot]);

  return (
    <StyledWrapper>
      <Button
        onClick={() => {
          setShowTimePicker(!showTimePicker);
        }}
        label={(
          <div className="clock-box">
            <ClockIcon className="clock" />
            <div>
              {hours}
              :
              {minutes}
              {' '}
              {timeSlot}
            </div>
          </div>
        )}
        classes={`btn-style ${showTimePicker ? 'btn-style--active' : ''}`}
      />
      {showTimePicker && (
        <div ref={dropdownRef} className="clock-times">
          <div className="clock-titles">
            <div className="clock-title">Hrs</div>
            <div className="clock-title">Min</div>
          </div>
          <div className="clock-cols">
            <div className="clock-time-col">
              <div>
                {[
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                ].map((item, index) => (
                  <div
                    key={index}
                    className={`clock-time-item ${
                      hours === item ? 'active-time' : ''
                    }`}
                    onClick={() => setHours(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className="clock-time-col">
              <div>
                {['00', '10', '20', '30', '40', '50'].map((item, index) => (
                  <div
                    key={index}
                    className={`clock-time-item ${
                      minutes === item ? 'active-time' : ''
                    }`}
                    onClick={() => setMinutes(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className="clock-time-col">
              <div>
                {['AM', 'PM'].map((item, index) => (
                  <div
                    key={index}
                    className={`clock-time-item ${
                      timeSlot === item ? 'active-time' : ''
                    }`}
                    onClick={() => setTimeSlot(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </StyledWrapper>
  );
}

TimePicker.propTypes = {
  classes: PropTypes.string,
};

export default TimePicker;
