import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import { createMerchant, getMerchantById, updateMerchant } from '../../api/merchants';
import { StyledWrapper } from '../../styles/formStyledWrapper';
import { getData } from '../../utils';
import Form from './Form';

function MerchantEditFrom() {
  const [loading, setLoading] = useState(false);
  const [merchantData, setMerchantData] = useState(null);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getMerchantById(id)
        .then((res) => {
          setMerchantData(getData(res));
          setLoading(false);
        })
        .catch((e) => {
          captureException(e);
          setLoading(false);
        });
    }
  }, [id]);

  function handleSubmit(data) {
    if (id) {
      return updateMerchant(id, data)
        .then(() => { history.push(`/console/merchants/${id}`); });
    }
    return createMerchant(data).then((res) => res.data);
  }
  return (
    <Layout header={(
      <Layout.Header
        title={merchantData?.name || 'New merchant'}
        loading={loading}
        backTitle="Merchants"
        backPath={`/console/merchants/${id}`}
      />
    )}
    >
      <StyledWrapper>
        <Form
          data={merchantData}
          loading={loading}
          merchantId={id}
          onSubmit={handleSubmit}
        />

      </StyledWrapper>
    </Layout>
  );
}

export default withGuard(MerchantEditFrom, [PERMISSIONS.MERCHANTS], { redirect: true });
