import http from 'services/http';

export const reorderCategoryPosition = ({ categoryId, position }) => http.post(`v1/categories/${categoryId}/move/`, { position }).then((res) => res.data);

export const createCategory = ({ name, menuId }) => http.post(
  'v1/categories/',
  { name, menu: menuId, items_positions: [0] },
).then((res) => res.data);

export const deleteCategory = (id) => http.delete(`v1/categories/${id}/`).then((res) => res.data);

export const editCategory = ({ id, name }) => http.patch(`v1/categories/${id}/`, { name }).then((res) => res.data);
