import { ShopOutlined, UserOutlined } from '@ant-design/icons';
import {
  Flex, Form, Space, Typography,
} from 'antd';
import React from 'react';

function Summary() {
  const form = Form.useFormInstance();
  const customer = form.getFieldValue('customer');
  const merchant = form.getFieldValue('merchant');
  return (
    <Flex gap={32} className="flex-column flex-lg-row">
      <Space size={12}>
        <ShopOutlined style={{ fontSize: 20 }} />
        <Typography>
          {merchant?.label}
          {' '}
          (
          {merchant?.merchant_type}
          )
        </Typography>
      </Space>
      <Space size={12} className="flex-column flex-md-row align-items-start align-items-md-center">
        <Space size={12}>
          <UserOutlined style={{ fontSize: 20 }} />
          <Typography>{customer?.name}</Typography>
        </Space>
        <Typography>{customer?.value}</Typography>
        <Typography>{customer?.email}</Typography>
      </Space>
    </Flex>
  );
}
export default Summary;
