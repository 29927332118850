import React from 'react';
import { useHistory } from 'react-router-dom';
import http from 'services/http';
import DrawerForm from './DrawerForm';

function Create() {
  const history = useHistory();

  async function handleCreate(data) {
    await http.post('/v1/organization/{{organizationId}}/parents/', data);
    handleBack();
  }
  function handleBack() {
    history.replace('/console/locations');
  }

  return (
    <DrawerForm
      open
      onSubmit={handleCreate}
      onClose={handleBack}
    />
  );
}

export default Create;
