import React, { useMemo } from 'react';

import {
  Divider, Radio, Space, Typography,
} from 'antd';
import OptionRow from './OptionRow';

function ItemTypes({ item, value, onChange }) {
  const itemTypes = useMemo(() => {
    if (!item?.item_types) {
      return [];
    }
    return item?.item_types
      .filter((t) => t.price)
      .map(({ price, size_id: sizeId, type }) => ({
        price,
        id: sizeId,
        title: type,
        discountedPrice: item?.discount?.discounted_prices?.find(
          (dp) => dp.size_id === sizeId,
        )?.discounted_price,
      }));
  }, [item]);

  if (!value && itemTypes[0]) {
    onChange(itemTypes[0]);
  }

  if (itemTypes.length < 2) {
    return null;
  }
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Text strong>Select Size</Typography.Text>
      <Radio.Group
        style={{ width: '100%', fontSize: 14 }}
        onChange={({ target }) => {
          const selectedType = itemTypes.filter(
            (type) => type.id === target.value,
          );
          if (selectedType.length) onChange(selectedType[0]);
        }}
        value={value?.id}
      >
        <div className="flex flex-column" style={{ gap: 8 }}>
          {itemTypes.map((itemType) => (
            <Radio value={itemType?.id} key={itemType?.id}>
              <OptionRow option={itemType} />
            </Radio>
          ))}
        </div>
      </Radio.Group>
      <Divider />
    </Space>
  );
}

export default ItemTypes;
