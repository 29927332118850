import { Form } from 'antd';
import React from 'react';

function FormItem({
  fullWidth = false, style, boolean = false, ...props
}) {
  return (
    <Form.Item
      valuePropName={boolean ? 'checked' : 'value'}
      style={{
        width: fullWidth ? '100%' : 300,
        maxWidth: '100%',
        ...style,
      }}
      {...props}
    />
  );
}

export default FormItem;
