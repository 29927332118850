import { PlusOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { UserManagementContext } from 'containers/AccountSettings/UserManagement/constants';
import React, { useContext } from 'react';
import { ReactComponent as Icon } from './SafeGaurdIcon.svg';

const { Text } = Typography;

function InitialComponent() {
  const { handleOpenPermissionForm } = useContext(UserManagementContext);

  return (
    <div className="flex flex-column justify-content-center align-items-center" style={{ minHeight: 400 }}>
      <Icon />
      <Space direction="vertical" align="center" className="mt-6 mb-8">
        <Text strong style={{ fontSize: 16 }}>Add your first member</Text>
        <Text style={{ color: '#666B6D', fontSize: 14 }}>
          Add a new member to this account with a distinct access level.
        </Text>
      </Space>
      <Button type="primary" onClick={() => handleOpenPermissionForm()} icon={<PlusOutlined />}>Add new member</Button>
    </div>
  );
}
export default InitialComponent;
