import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { INNER_ROUTES, MainRoutes } from 'constants/routes';

const ROUTES = [...Object.values(MainRoutes), ...INNER_ROUTES];
function Routes() {
  return (
    <Switch>
      {ROUTES.map((route, index) => {
        if (route.redirect) return <Redirect to={route.redirect} key={route.path} />;
        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
}

export default Routes;
