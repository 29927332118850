import ReportToolbar from 'containers/Reports/components/ReportToolbar';
import ReportLayout from 'containers/Reports/layout';
import { getDateRange, getFilterQuery } from 'containers/Reports/utils';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getData } from 'utils';
import './index.css';
import { captureException } from 'utils/errors';
import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import OrderSourceReport from './OrderSourceReport';
import OrderTypeReport from './OrderTypeReport';
import OverallSummary from './OverallSummary';
import { fetchGeneralReport } from './requests';

function GeneralReport() {
  const [filterData, setFilterData] = useState();
  const [reportData, setReportData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (filterData && filterData.options?.locations) {
      getReportData();
    }
  }, [filterData]);

  const getReportData = () => {
    setLoading(true);

    fetchGeneralReport(getFilterQuery(filterData))
      .then((res) => {
        if (res.status === 'success') setReportData(getData(res));
        else toast.error(getData(res)?.title || 'Something went wrong');
        setLoading(false);
      }).catch((e) => {
        captureException(e);
        setLoading(false);
      });
  };

  const dateRange = getDateRange({
    range: filterData?.selected?.date?.range,
    separator: '-',
  });
  return (
    <ReportLayout
      title="General Report"
      description={`Changes to your account from <b>${dateRange}</b>, based on the date of the account change. Useful to record how your account changed.`}
      defaultRoute="/console/reports"
    >
      <ReportToolbar
        filterData={{ ...filterData }}
        setFilterData={setFilterData}
        hasOrderTypeFilter
        type="general"
        hasExport={false}
      />
      <OverallSummary reportData={reportData} loading={loading} />
      {/* <RDSReport reportData={reportData} loading={loading} />*/}
      <OrderSourceReport reportData={reportData?.order_counts_by_source} loading={loading} dateRange={dateRange} />
      <OrderTypeReport reportData={reportData?.order_counts_by_type} loading={loading} dateRange={dateRange} />
    </ReportLayout>
  );
}

export default withGuard(GeneralReport, [PERMISSIONS.REPORTS], { redirect: true });
