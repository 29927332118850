import { EMPTY_ARRAY } from 'constants';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import React from 'react';
import { useHistory } from 'react-router-dom';

function withGuard(Component, permissions, {
  redirect = false,
  fallback = null,
}) {
  return function (props) {
    const hasPermission = useHasAnyOfPermissions(permissions || EMPTY_ARRAY);
    const history = useHistory();
    if (hasPermission) {
      return <Component {...props} />;
    }
    if (redirect) {
      history.replace('/login');
    }
    return fallback;
  };
}

export default withGuard;
