import { CheckOutlined, InfoCircleFilled } from '@ant-design/icons';
import {
  Button, Flex, Skeleton, Typography,
} from 'antd';
import SimpleModal from 'components/SimpleModal';
import * as orderStatus from 'constants/status';
import { isOmniPartner, isSelfDelivery } from 'containers/OrdersTab/utils';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import { getHttpErrorMessage } from 'services/http';
import { acceptCateringOrder, fetchOrganizationConfig } from '../requests';

function Actions({
  order, loading: orderLoading, fetchOrder, messageApi,
}) {
  const { orderNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const isSelfDeliveryOrder = isSelfDelivery(order);
  const isSelfDeliveryOrOmniPartner =
    isSelfDeliveryOrder || isOmniPartner(order);

  useEffect(() => {
    if (order?.organization_id) {
      fetchOrganizationConfig(order.organization_id).then((res) => {
        setPhone(res.data.client_config?.general?.phone);
      }).catch(captureException);
    }
  }, [order]);
  const handleCancelOrder = () => {
    setOpen(true);
  };
  const handleAcceptOrder = () => {
    setLoading(true);
    acceptCateringOrder(orderNumber).then((res) => {
      messageApi.open({ type: 'success', content: 'Your order is successfully accepted!' });
      fetchOrder();
    }).catch((e) => {
      captureException(e);
      messageApi.open({ type: 'error', content: getHttpErrorMessage(e) });
    })
      .finally(() => setLoading(false));
  };

  const ORDER_ACTIONS = [
    {
      oStatus: [orderStatus.NEW],
      buttons: (
        <ButtonWrapper>
          <Button
            danger
            onClick={handleCancelOrder}
            disabled={loading}
            className="w-100"
          >
            Cancel Order
          </Button>
          <Button
            type="primary"
            onClick={handleAcceptOrder}
            loading={loading}
            disabled={isSelfDeliveryOrOmniPartner}
            className="w-100"
          >
            Accept Order
          </Button>
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.IN_PROGRESS],
      buttons: (
        <ButtonWrapper vertical>
          <Typography.Text>
            <InfoCircleFilled className="mr-2" />
            {' '}
            Order has been accepted!
          </Typography.Text>
          <Button
            danger
            onClick={handleCancelOrder}
            disabled={loading}
            className="w-100"
          >
            Cancel Order
          </Button>
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.ON_THE_WAY],
      buttons: (
        <ButtonWrapper>
          <Typography.Text>Order is on the way</Typography.Text>
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.COMPLETED],
      buttons: (
        <ButtonWrapper>
          <Typography.Text style={{ color: '#02862F' }}>
            <CheckOutlined className="mr-2" />
            Order has been completed!
          </Typography.Text>
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.CANCELLED_BY_RESTAURANT],
      buttons: (
        <ButtonWrapper>
          <Typography.Text style={{ color: '#666B6D' }}>
            <InfoCircleFilled className="mr-2" />
            Order has been cancelled!
          </Typography.Text>
        </ButtonWrapper>
      ),
    },
  ];
  const setting = ORDER_ACTIONS.find((s) => s.oStatus.includes(order?.state));

  if (!order || orderLoading) {
    return (
      <ButtonWrapper>
        <Skeleton.Button block />
        <Skeleton.Button block />
      </ButtonWrapper>
    );
  }
  return (
    <>
      {setting?.buttons}
      <SimpleModal
        title="Cancel order"
        open={open}
        setOpen={setOpen}
        footer={(<></>)}
      >
        <Typography.Text>
          To proceed with cancellation, please call the admin at (
          <b>{phone}</b>
          ) to confirm.
          Once cancelled, the customer will be notified and the order will be cancelled.
        </Typography.Text>
      </SimpleModal>
    </>
  );
}

function ButtonWrapper({ children, vertical }) {
  return (
    <Flex justify="center" vertical={vertical} align={vertical ? 'center' : 'start'} className="w-100" gap={12}>
      {children}
    </Flex>
  );
}
export default Actions;
