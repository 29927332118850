import React from 'react';
import {
  Col,
  Modal, Row, Space, Tag, Typography,
} from 'antd';

import { Divider } from '@mui/material';
import moment from 'moment';
import { formatAmount } from 'utils';

function DetailsModal({
  data, onClose,
}) {
  const {
    description,
    amount,
    author,
    placeholder,
    type,
    created_at: date,
  } = data || {};

  return (
    <Modal
      title={(
        <>
          Credit history
          <Tag className="ml-2">{type}</Tag>
        </>
      )}
      onCancel={onClose}
      onOk={onClose}
      open={Boolean(data)}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Row>
        <Col xs={12}>
          <Item title="Amount" value={formatAmount(amount, { isCent: true })} />
        </Col>
        <Col xs={12}>
          <Item title="Date" value={date ? moment(date).format('MMM D, hh:mm A') : '-'} />
        </Col>
        <Col xs={12}>
          <Item title="Type" value={type} />
        </Col>
        <Col xs={12}>
          <Item title="Payment placeholder" value={author} />
        </Col>
      </Row>

      <Space wrap size={60} className="mt-3" />
      {
        description && (
          <>
            <Divider className="my-3" />
            <Item title="Description" value={description} />
          </>
        )
      }
    </Modal>

  );
}

function Item({ title, value }) {
  return (
    <div>
      <Typography.Text type="secondary" className="d-block">{title}</Typography.Text>
      <Typography.Text strong>{value || '-'}</Typography.Text>
    </div>
  );
}

export default DetailsModal;
