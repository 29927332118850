import { getAllCustomers } from 'api/customers';
import Tabs from 'components/common/Tabs';
import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { getData } from 'utils';
import { captureException } from 'utils/errors';
import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import usePagination from '../../hooks/usePagination';
import { StyledWrapper } from './styles';
import Table from './Table';

function Customers() {
  const [searchKey, setSearchKey] = useState('');
  const [customersLoading, setCustomersLoading] = useState(true);
  const [customers, setCustomers] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  const TABS = [
    {
      title: 'All',
      id: 1,
    },
    {
      title: 'New',
      id: 2,
    },
    {
      title: 'Active',
      id: 3,
    },
    {
      title: 'Inactive',
      id: 4,
    },
    {
      title: 'Lost',
      id: 5,
    },
  ];
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const debounced = useDebouncedCallback((value) => {
    resetPaging();
    setSearchKey(value);
  }, 500);

  useEffect(() => {
    getCustomers();
  }, [searchKey, activeTab]);

  const handleStatusName = () => {
    if (activeTab?.id === TABS[0]?.id) {
      return '';
    }
    return activeTab.title.toLocaleLowerCase();
  };

  const getCustomers = () => {
    setCustomersLoading(true);
    getAllCustomers(searchKey, page, handleStatusName()).then((res) => {
      setCustomersLoading(false);
      setCustomers([...getData(res)?.results]);
      setPagination({
        count: getData(res)?.count,
        next: getData(res)?.next,
        previous: getData(res)?.previous,
      });
    })
      .catch((e) => {
        captureException(e);
        setCustomersLoading(false);
      });
  };

  const {
    setPage, page, pagination, setPagination, paginationEl,
  } =
    usePagination(getCustomers, customersLoading);

  const handleSearchChange = (e) => {
    debounced(e.target.value);
  };

  const resetPaging = () => {
    setPage(1);
    setPagination({
      count: null,
      next: null,
      previous: null,
    });
  };

  // const handleChangeSort = (val) => {
  //   setSortOption(val);
  // };

  return (
    <Layout header={(
      <Layout.Header
        title="Customers"
        onSearch={handleSearchChange}
        searchPlaceholder="Search customers"
      />
    )}
    >
      <StyledWrapper>
        {/* Tabs */}
        <Tabs
          classes="tabs-box"
          tabsList={TABS}
          activeTab={activeTab}
          setActiveTab={(t) => {
            resetPaging();
            setActiveTab(t);
          }}
        />
        {/* Table */}
        <div className="table-box">
          <Table
            loading={customersLoading}
            pagination={pagination}
            resetPaging={resetPaging}
            items={customers}
          />
          <div className="pagination">{paginationEl}</div>
        </div>
      </StyledWrapper>
    </Layout>
  );
}

export default withGuard(Customers, [PERMISSIONS.CUSTOMERS], { redirect: true });
