import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import {
  Button, Dropdown, Flex, message, Radio, Skeleton, Space, Tag, theme, Typography,
} from 'antd';
import { OrderStatusLabel, OrderStatusTagColor } from 'constants/status';
import { getHttpErrorMessage } from 'services/http';
import { captureException } from 'utils/errors';
import useIsMobile from 'hooks/useIsMobile';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { getOrderType } from 'utils/order';
import { changeOrderState } from './requests';
import ChangeStatusConfirmation from './ChangeStatusConfirmation';
import './index.css';

const { useToken } = theme;

function Header({
  order, loading, onClose, handleStateChange,
}) {
  const isMobile = useIsMobile();
  const { token } = useToken();
  const [nextState, setNextState] = useState(order.state);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setNextState(order?.state);
  }, [order?.state])
  const statusOptions = useMemo(() => {
    const nextStates = order?.possible_next_states?.map((nextState) => (
      {
        label: nextState.state_label,
        key: nextState.state_id,
        color: OrderStatusTagColor[nextState.state_id],
      }
    )) || [];
    return [{
      label: OrderStatusLabel[order.state],
      key: order.state,
      color: OrderStatusTagColor[order.state],
    }, ...nextStates];
  }, [order?.possible_next_states, order.state]);

  const onConfirmChange = () => {
    changeOrderState({ orderId: order.id, state: nextState })
      .then((res) => handleStateChange())
      .catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e));
      });
  };

  const onCancelChange = () => {
    setOpen(false);
    setNextState(order.state);
  }

  if (!order || loading) {
    return <Skeleton paragraph={{ rows: 1 }} />;
  }

  return (
    <Flex vertical gap={8}>
      <Flex justify="space-between">
        <Typography>
          Order #
          {order.number}
          {' - '}
          {order.id}
        </Typography>
        <CloseOutlined onClick={onClose} />
      </Flex>

      <Flex vertical={isMobile} justify="space-between" gap={8} align={isMobile ? 'start' : 'center'}>
        <Space>
          <Tag>{getOrderType(order)}</Tag>

          <Dropdown
            menu={{ items: statusOptions }}
            dropdownRender={(menu) => (
              <Flex
                vertical
                gap={8}
                style={{
                  background: 'white',
                  borderRadius: 4,
                  padding: '8px 12px',
                  boxShadow: token.boxShadowSecondary,
                }}
              >
                {menu.props.items.map((item) => (
                  <Radio
                    key={item.key}
                    checked={item.key === nextState}
                    onChange={() => {
                      setNextState(item.key);
                      setOpen(true);
                    }}
                  >
                    <Tag color={item.color} className="font-bold py-0.5">{item.label}</Tag>
                  </Radio>
                ))}
              </Flex>
            )}
          >
            <Flex gap={0}>
              <Tag
                color={OrderStatusTagColor[order.state]}
                style={{ borderRadius: '4px 0 0 4px ' }}
                className="font-bold py-1 px-3 mr-0"
              >
                {OrderStatusLabel[order.state]}
              </Tag>
              <Button
                icon={<DownOutlined style={{ fontSize: 12 }} />}
                style={{
                  width: 40,
                  height: 32,
                  borderRadius: '0 4px 4px 0',
                  borderLeft: 'none',
                }}
                className="status-selector-button"
              />
            </Flex>
          </Dropdown>

        </Space>

        <Typography.Text type="secondary" className="justify-end">
          Placed on:
          {' '}
          {moment(order.timestamp).format('MMM D, LT')}
        </Typography.Text>
      </Flex>

      <ChangeStatusConfirmation
        open={open}
        setOpen={setOpen}
        onConfirmChange={onConfirmChange}
        onCancel={onCancelChange}
        state={OrderStatusLabel[order.state]}
        nextState={OrderStatusLabel[nextState]}
      />
    </Flex>
  );
}
export default Header;
