import { ReactComponent as AddIcon } from 'assets/image/add.svg';
import { ReactComponent as CampaignIcon } from 'assets/image/campaign.svg';
import Button from 'components/common/Button';
import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { getData } from 'utils';
import { captureException } from 'utils/errors';
import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import { getAllNotifications } from '../../api/marketing';
import usePagination from '../../hooks/usePagination';
import { StyledWrapper } from './styles';
import Table from './Table';

function Marketing() {
  const [searchKey, setSearchKey] = useState('');
  const [notificationsLoading, setNotificationsLoading] = useState(true);
  const [notifications, setNotifications] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);

  const history = useHistory();

  const TABS = [
    {
      title: 'All',
      id: 1,
    },
    {
      title: 'New',
      id: 2,
    },
    {
      title: 'Active',
      id: 3,
    },
    {
      title: 'Inactive',
      id: 4,
    },
    {
      title: 'Lost',
      id: 5,
    },
  ];
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const SORT_OPTIONS = [
    {
      title: 'Spent amount',
      element: <div className="">Spent amount</div>,
    },
    {
      title: 'Orders',
      element: <div className="">Orders</div>,
    },
    {
      title: 'Order date',
      element: <div className="">Order date</div>,
    },
  ];
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);

  const debounced = useDebouncedCallback((value) => {
    resetPaging();
    setSearchKey(value);
  }, 500);

  useEffect(() => {
    getNotifications();
  }, [searchKey, activeTab]);

  const handleStatusName = () => {
    if (activeTab?.id === TABS[0]?.id) {
      return '';
    }
    return activeTab.title.toLocaleLowerCase();
  };

  const getNotifications = () => {
    setNotificationsLoading(true);
    getAllNotifications(searchKey, page, handleStatusName()).then((res) => {
      setNotificationsLoading(false);
      setNotifications([...getData(res)?.results]);
      setPagination({
        count: getData(res)?.count,
        next: getData(res)?.next,
        previous: getData(res)?.previous,
      });
    })
      .catch((e) => {
        captureException(e);
        setNotificationsLoading(false);
      });
  };

  const {
    setPage, page, pagination, setPagination, paginationEl,
  } =
    usePagination(getNotifications, notificationsLoading);

  const handleSearchChange = (e) => {
    debounced(e.target.value);
  };

  const resetPaging = () => {
    setPage(1);
    setPagination({
      count: null,
      next: null,
      previous: null,
    });
  };

  const handleCreateNotif = () => {
    history.push('/console/marketing/create');
  };

  const reload = () => {
    if (page !== 1) {
      resetPaging();
    }
    getNotifications();
  };

  return (
    <Layout header={(
      <Layout.Header
        title="Marketing"
        searchPlaceholder="Search by campaign"
        onSearch={handleSearchChange}
        extra={[
          <Button
            label="Create new"
            leadingIcon={<AddIcon />}
            type="text"
            classes="add-btn"
            type="neutral"
            onClick={() => handleCreateNotif()}
          />,
        ]}
      />
    )}
    >
      <StyledWrapper>
        {/* Table */}
        {notifications?.length ? (
          <div className="table-box">
            <Table
              loading={notificationsLoading}
              pagination={pagination}
              items={notifications}
              reload={reload}
            />
            <div className="pagination">{paginationEl}</div>
          </div>
        ) : (
          <div className="empty">
            <CampaignIcon className="tag" />
            <p className="empty-title">You don’t have any campaigns!</p>
            <p className="empty-desc">
              Start making new campaigns by createing push notifications and more.
              {' '}
            </p>
            <Button
              label="Create a push notification"
              type="text"
              classes="empty-btn"
              type="filled"
              onClick={() => handleCreateNotif()}
            />
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
}

export default withGuard(Marketing, [PERMISSIONS.MARKETING], { redirect: true });
