import React, { useEffect, useState } from 'react';

import { ReactComponent as ClockIcon } from 'assets/image/clock.svg';
import { InfoSquareIcon } from 'assets/icons';
import Tooltip from 'components/common/Tooltip';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import { PERMISSIONS } from 'constants/permissions';
import { Typography } from 'antd';
import { StyledWrapper } from './styles';
import { priceTransformer } from '../../../utils';

const Overview = React.memo(({ overview }) => {
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);

  return (
    <StyledWrapper>
      <div className="overview-box">
        <div className="overview-top">
          <Typography.Title level={3}>Overview</Typography.Title>
        </div>
      </div>
      <div className="table">
        {[
          {
            title: 'Total GMV',
            info: 'Gross Merchandise Value (GMV) shows your total value of sales over a certain period of time.',
            price: `$${Number(
              priceTransformer(overview?.gmv, true),
            ).toLocaleString()}`,
            hasDate: true,
          },
          {
            title: 'Total orders',
            info: null,
            price: overview?.total_orders || 0,
            hasDate: true,
          },
          {
            title: 'Orders in progress',
            info: 'Total number of orders excluding completed, rejected, and cancelled orders.',
            price: overview?.inprogress_orders,
            hasDate: true,
          },
          {
            title: 'Cancelled orders ',
            info: 'Total number of cancelled orders.',
            price: overview?.canceled_orders,
            hasDate: true,
          },
          ...(isAdmin ? [
            {
              title: 'Subtotal',
              price: `$${Number(
                priceTransformer(overview?.subtotal, true),
              ).toLocaleString()}`,
              hasDate: true,
            },
            {
              title: 'New users',
              info: 'Total number of new users.',
              price: overview?.new_users_count,
              hasDate: true,
            },
            {
              title: 'Omni-merchants live',
              info: 'Total number of omni merchants that are currently accepting orders',
              price: overview?.omni_live_merchants,
              hasDate: false,
            },
            {
              title: 'Regular merchants live',
              info: 'Total number of non-omni merchants that are currently accepting orders',
              price: overview?.non_omni_live_merchants,
              hasDate: false,
            },
          ] : []),
        ].map((o, index) => (
          <div className="cell" key={index}>
            <div className="f-row f-row-between">
              <div className="f-row">
                <span className="cell-title">{o.title}</span>
                {o.info ? (
                  <Tooltip
                    tooltipBoxClasses="tooltip-box"
                    label={<InfoSquareIcon />}
                    tooltipBody={(
                      <div className="tooltip-row tooltip-previous">
                        <span>{o.info}</span>
                      </div>
                    )}
                    tooltipBodyClasses="tooltip"
                  />
                ) : null}
              </div>
              {o?.hasDate ? <div className="cell-date">Today</div> : null}
            </div>
            <div className="cell-price">{o.price}</div>
          </div>
        ))}
      </div>
    </StyledWrapper>
  );
});

export default Overview;
