import { Card, Flex, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

function CardLink({
  title, description, icon, link, isExternal, onClick, ...props
}) {
  const cardInfo = (
    <Card
      className="h-100"
      hoverable
    >
      <Flex vertical gap={16}>
        <Flex gap={12} align="center">
          {React.cloneElement(icon, { style: { fontSize: 24 } })}
          <Typography.Text
            level={3}
            style={{ fontSize: 16, fontWeight: 700 }}
          >
            {title}
          </Typography.Text>
        </Flex>
        {description && (
          <Typography.Paragraph type="secondary">
            {description}
          </Typography.Paragraph>
        )}
      </Flex>
    </Card>
  );
  const onCardClick = (e) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };
  if (isExternal) {
    return (
      <a href={link} {...props}>
        {cardInfo}
      </a>
    );
  }

  return (
    <Link to={link} {...props} onClick={onCardClick}>
      {cardInfo}
    </Link>
  );
}
export default CardLink;
