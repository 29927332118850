import React, { useState } from 'react';

import {
  Form, Input, Modal, Typography,
} from 'antd';
import { addMerchantOwner } from 'api/merchants';
import { useParams } from 'react-router-dom';
import { errorHandling } from 'utils';
import { captureException } from 'utils/errors';

function FormModal({
  open,
  onClose,
  setOwner,
  ownerEmail,
}) {
  const [form] = Form.useForm();
  const { id: merchantId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    setIsLoading(true);
    const { email, password } = form.getFieldsValue();
    addMerchantOwner(merchantId, {
      email,
      password,
    }).then((res) => {
      if (setOwner) {
        setOwner({ email });
      }
      setIsLoading(false);
      form.resetFields();
      onClose();
    }).catch((e) => {
      captureException(e);
      errorHandling(e);
      setIsLoading(false);
    });
  }

  return (
    <Modal
      open={open}
      onOk={form.submit}
      onCancel={onClose}
      okText={ownerEmail ? 'Save' : 'Add'}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      title={`${ownerEmail ? 'Edit' : 'Add'} merchant owner`}
    >
      <Typography.Text>
        You can link an existing merchant profile by entering their email and password or you can easily update a merchant's password by typing and confirming the new one.
      </Typography.Text>
      <Form
        name="basic"
        layout="vertical"
        form={form}
        initialValues={{ email: ownerEmail }}
        onFinish={handleSubmit}
        autoComplete="off"
        style={{ marginTop: 8 }}
      >
        <Form.Item
          label="Email"
          name="email"
          autoComplete="disabled"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please enter a valid email address!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          autoComplete="no-password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            {
              min: 7,
            },
          ]}
        >
          <Input.Password autoComplete={false} />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

      </Form>

    </Modal>
  );
}

export default FormModal;
