import { DeliveryIcon, PickupIcon } from 'assets/icons';
import BadgeLabel from 'components/common/BadgeLabel';
import React from 'react';

export const getOrderType = (order) => (order?.has_delivery ? (
  <BadgeLabel
    label="Delivery"
    leadingIcon={<DeliveryIcon />}
    classes="type-size p-1"
  />
) : (
  <BadgeLabel
    label="Pick Up"
    leadingIcon={<PickupIcon />}
    classes="type-size p-1"
  />
));
