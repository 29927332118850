import {
  Button, Card, Form, Input, message, Typography,
} from 'antd';
import { fetchMyOrganizations } from 'api/organizations';
import { ChristmasOCLogoIcon, OCLogoIcon } from 'assets/icons';
import Layout from 'components/Layout';
import { AdminContext } from 'constants/contexts';
import { HAS_CHRISTMAS_THEME } from 'constants/index';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import http, { getHttpErrorMessage } from 'services/http';
import { captureException } from 'utils/errors';
import { getData } from '../../utils';

function Login() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {
    isLoggedIn, organization, setOrganization, setUser,
  } = useContext(AdminContext);
  const handleSubmit = (data) => {
    setLoading(true);
    http.post('user/login/super-user/', data, {
      headers: {
        Authorization: '',
      },
    })
      .then((res) => {
        const authToken = getData(res)?.key;

        if (authToken) {
          http.get('user/rest-auth/user/', {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }).then((res) => {
            setUser({ ...res.data, authToken });
            fetchMyOrganizations().then((data) => {
              if (!organization && data.results?.length) {
                setOrganization(data.results[0]);
              }
              history.push({
                pathname: '/console',
                state: { from: 'login' },
              });
              setLoading(false);
            }).catch((e) => {
              captureException(e);
              message.error(getHttpErrorMessage(e));
              setLoading(false);
            });
          }).catch((e) => {
            captureException(e);
            message.error(getHttpErrorMessage(e));
            setLoading(false);
          });
        }
      })
      .catch((e) => {
        captureException(e);
        setLoading(false);
        message.error(getHttpErrorMessage(e));
      });
  };

  if (isLoggedIn) {
    history.push({
      pathname: '/console',
      state: { from: 'login' },
    });
  }

  return (
    <Layout hasSidebar={false}>
      <div className="w-screen h-screen flex items-center justify-center">
        <Card className="w-96">
          <div className="flex items-center justify-center mb-5">
            {HAS_CHRISTMAS_THEME ? <ChristmasOCLogoIcon /> : <OCLogoIcon />}
          </div>
          <Typography.Title level="1" className="mt-3" style={{ fontSize: 24 }}>
            Sign in to your account
          </Typography.Title>
          <Form
            onFinish={handleSubmit}
            className="mt-6"
            validateTrigger="onSubmit"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input placeholder="email" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password placeholder="password" />
            </Form.Item>
            <Link to="/forget-password">Forgot your password?</Link>
            <Form.Item>
              <Button
                block
                loading={loading}
                disabled={loading}
                type="primary"
                htmlType="submit"
                className="mt-5"
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Layout>
  );
}

export default Login;
