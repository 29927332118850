import { message } from 'antd';
import { resetPasswordConfirm } from 'api/auth';
import Layout from 'components/Layout';
import Navbar from 'components/Layout/Navbar';
import Loading from 'components/Loading';
import { AdminContext } from 'constants/contexts';
import React, { useContext, useEffect, useState } from 'react';
import {
  Card, Col, Container, Form, Row,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { captureException } from 'utils/errors';
import { Colors } from '../../assets/colors';

const Submit = styled.div`
  a {
    color: ${Colors.white} !important;
    font-weight: bold;
    font-size: 15px !important;
  }
`;

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState({});
  const { isLoggedIn } = useContext(AdminContext);
  const handleChange = (e) => {
    const { value, name } = e.target;

    setForm({ ...form, [name]: value });
  };

  const submit = () => {
    if (!form.new_password1 || !form.new_password2) return;
    setLoading(true);
    const { uid, token } = props.match.params;
    resetPasswordConfirm({ ...form, uid, token })
      .then((res) => {
        message.success('Password successfully changed!');
        setRedirect(true);
        setLoading(false);
      })
      .catch((e) => {
        captureException(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    setRedirect(isLoggedIn);
  }, [isLoggedIn]);

  const { from } = props.location.state || { from: { pathname: '/console' } };

  if (redirect === true) {
    return <Redirect to={from} />;
  }

  return (
    <Layout hasSidebar={false}>
      <Navbar />
      <div
        style={{ height: '100vh', display: 'flex', justifyContent: 'center' }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xl={10} lg={12} md={9}>
              <Card className="o-hidden border-0 shadow-lg my-5">
                <Card.Body className="p-3">
                  <Row className="">
                    <Col md={12}>
                      <h3 className="h4 text-dark my-2 text-center font-weight-bold">
                        Confirm Reset Password
                      </h3>
                      <p className="p-0 m-0 text-center">
                        Enter your new password
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <div className="col-lg-12">
                      <div className="p-5">
                        <Form className="user" onSubmit={submit}>
                          <Form.Group className="row">
                            <Col md={{ span: 6, offset: 3 }} className="mb-3">
                              <Form.Control
                                type="password"
                                className="form-control form-control-user"
                                placeholder="New Password"
                                name="new_password1"
                                onChange={handleChange}
                                value={form.email}
                              />
                              {error.firstnameError && (
                                <Form.Control.Feedback
                                  style={{ display: 'block' }}
                                  type="invalid"
                                >
                                  *required
                                </Form.Control.Feedback>
                              )}
                            </Col>
                            <Col md={{ span: 6, offset: 3 }} className="mb-3">
                              <Form.Control
                                type="password"
                                className="form-control form-control-user"
                                placeholder="Confirm New Password"
                                name="new_password2"
                                onChange={handleChange}
                                value={form.email}
                              />
                              {error.firstnameError && (
                                <Form.Control.Feedback
                                  style={{ display: 'block' }}
                                  type="invalid"
                                >
                                  *required
                                </Form.Control.Feedback>
                              )}
                            </Col>
                          </Form.Group>

                          <Submit className="text-center">
                            <a
                              onClick={submit}
                              className="btn btn-user"
                              style={{
                                color: Colors.white,
                                background: '#04B261',
                                minWidth: 120,
                                fontWeight: 'bold',
                              }}
                            >
                              {!loading ? (
                                'Reset Password'
                              ) : (
                                <Loading noPadding />
                              )}
                            </a>
                          </Submit>
                          <br />
                        </Form>
                      </div>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default Login;
