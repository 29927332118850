import { Tag, Typography } from 'antd';
import BaseTable from 'components/Table';
import { UserManagementContext } from 'containers/AccountSettings/UserManagement/constants';
import React, { useContext, useState } from 'react';
import { TABLE_RENDER_TYPES } from 'utils/table';
import InitialComponent from './InitialComponent';
import MoreDetailModal from './MoreDetailModal';
import TableAction from './TableAction';

function PermissionsTable({ fetchPermissions, handleRefetch }) {
  const { roles } = useContext(UserManagementContext);
  const [open, setOpen] = useState(false);
  const [moreOptions, setMoreOptions] = useState({ type: '', options: [], user: '' });

  const renderOptions = ({ options, type, user }) => {
    if (options?.length < 3) return options?.join(', ');
    return (
      <span>
        {options.slice(0, 2).join(', ')}
        ,
        <Typography.Link
          style={{ marginLeft: 4 }}
          target="_blank"
          onClick={(e) => {
            setMoreOptions({ options, type, user });
            setOpen(true);
          }}
        >
          +
          {options.length - 2}
          {' '}
          more
        </Typography.Link>
      </span>
    );
  };

  const renderGroups = (groups, item) => {
    const roles = [];
    groups?.forEach((group) => {
      roles.push(group.name);
    });
    return renderOptions({ options: roles, type: 'Roles', user: item.email });
  };

  const renderParents = (parents, item) => {
    if (item.access_all_parents) return 'All locations';
    const locations = [];
    parents?.forEach((group) => {
      locations.push(group.title);
    });
    return renderOptions({ options: locations, type: 'Locations', user: item.email });
  };

  const renderCreated = (created, item) => (item.is_active ? TABLE_RENDER_TYPES.DATE(created) : <Tag>Invitation sent</Tag>);
  const renderUserName = (email, item) => {
    if (item.first_name) {
      return (
        <div className="flex flex-column">
          <Typography.Text>
            {item.first_name}
            {' '}
            {item.last_name}
          </Typography.Text>
          <Typography.Text style={{ fontSize: 13, color: 'rgba(112, 112, 112, 1)' }}>{email}</Typography.Text>
        </div>
      );
    }
    return email;
  };
  const columns = [
    { title: 'member', key: 'email', render: renderUserName },
    {
      title: 'role',
      key: 'groups',
      render: renderGroups,
      filters: roles?.map((r) => ({ text: r.name, value: r.name })),
    },
    { title: 'location', key: 'parent_names', render: renderParents },
    { title: 'created', key: 'created_at', render: renderCreated },
    { title: '', render: (_, data) => <TableAction data={data} onRemove={handleRefetch} /> },
  ];

  return (
    <>
      <BaseTable
        columns={columns}
        fetchFn={fetchPermissions}
        customEmptyComponent={<InitialComponent />}
        scroll={{ x: 400 }}
      />
      <MoreDetailModal open={open} setOpen={setOpen} data={moreOptions} />
    </>
  );
}

export default PermissionsTable;
