import React, { useContext, useEffect, useState } from 'react';

import NewTabLink from 'components/common/NewTabLink';
import PaginateTable from 'components/common/PaginateTable';
import moment from 'moment';
import { priceTransformer } from 'utils';
import { useHistory } from 'react-router';
import Dropdown from 'components/common/Dropdown';
import { ReactComponent as MoreIcon } from 'assets/image/more.svg';
import Switch from 'components/common/Switch';
import { getCouponTypeDisplayTitle } from '../coupon-type';
import { StyledTable } from './styles';
import { FIXEDAMOUNT_COUPON_TYPE } from '../constants';

function Table({
  categoryTitle,
  loading,
  items,
  pagination,
  resetPaging,
  onDelete,
  onEditStatus,
  onEdit,
  ...props
}) {
  const history = useHistory();

  const ROW_OPTIONS = [
    {
      title: 'Edit coupon',
      element: (row) => (
        <div className="more-btn--list-item">Edit coupon</div>
      ),
    },
    {
      title: 'Delete',
      element: (row) => (
        <div className="more-btn--list-item more-btn--list-item-red">
          Delete
        </div>
      ),
    },
  ];

  const handleFormatDate = (start, end) => (start && end
    ? `${moment(start)?.format('MMM D')} - ${moment(end)?.format('MMM D')}`
    : ' - ');
  const moreOptions = (row) => ROW_OPTIONS.map((r) => ({ ...r, element: r.element(row) }));
  const handleMoreOption = (val, row) => {
    switch (val) {
    case ROW_OPTIONS[0].title: // Edit
      onEdit(row.id);
      break;
    case ROW_OPTIONS[1].title: // Delete
      onDelete(row);
      break;

    default:
      break;
    }
  };
  return (
    <StyledTable>
      {/* Table */}
      <PaginateTable
        loading={loading}
        headers={[
          { title: 'Title', width: '45%' },
          { title: 'Code', width: '15%' },
          { title: 'Type', width: '10%' },
          { title: 'Amount', width: '10%' },
          { title: 'Active Date', width: '10%' },
          { title: 'Active', width: '5%' },
          { title: '', width: '5%' },
        ]}
        dataArray={loading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : items}
        rowCreators={[
          (row, index) => <div className="">{row.name}</div>,
          (row, index) => <div className="">{row.code}</div>,
          (row, index) => (
            <div className="">{getCouponTypeDisplayTitle(row.type)}</div>
          ),
          (row, index) => (
            <div className="strong">
              {row?.type === FIXEDAMOUNT_COUPON_TYPE
                ? priceTransformer(row.amount?.toFixed(2))
                : `${priceTransformer(row.amount?.toFixed(2), true)} %`}
            </div>
          ),
          (row, index) => (
            <div className="">
              {handleFormatDate(row.start_date, row.expiry_date)}
            </div>
          ),
          (row, index) => (
            <div onClick={(e) => e.stopPropagation()}>
              <Switch
                checked={!row.is_disabled}
                onChange={onEditStatus.bind(this, row)}
              />
            </div>
          ),
          (row, index) => (
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                label={<MoreIcon />}
                options={moreOptions(row)}
                onChange={(val) => handleMoreOption(val, row)}
                selected={null}
                noArrow
                btnClass="more-btn"
                btnOpenClass="more-btn--open"
                optionClass="list-item-custom"
              />
            </div>
          ),
        ]}
        noRecord="There are no records to display."
        onClick={(row) => {
          history.push(`/console/coupons/form/${row?.id}`);
        }}
        newRowClasses="new-row"
        dataPaging={pagination}
        tableStyle={{
          width: '400px',
          minWidth: '100%',
        }}
      />
    </StyledTable>
  );
}

export default Table;
