import { Table } from 'antd';
import ReportToolbar from 'containers/Reports/components/ReportToolbar';
import ReportLayout from 'containers/Reports/layout';
import { getDateRange, getFilterQuery, tableLocale } from 'containers/Reports/utils';
import useIsMobile from 'hooks/useIsMobile';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { getData } from 'utils';
import { captureException } from 'utils/errors';
import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import columns from './columns';
import './index.css';
import { fetchOrdersReport } from './requests';

function OrdersReport() {
  const isMobile = useIsMobile();
  const [filterData, setFilterData] = useState({
    selected: {
      excludeStates: [],
    },
  });
  const [reportData, setReportData] = useState();
  const [pagination, setPagination] = useState({ current: 1, total: 0, needAPICall: false });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (filterData && pagination.needAPICall) {
      getReportData(pagination.current);
    }
  }, [pagination.current]);

  useEffect(() => {
    if (filterData && filterData.options?.locations) {
      getReportData(1);
    }
  }, [filterData]);

  const getReportData = (page) => {
    setLoading(true);

    fetchOrdersReport({ query: getFilterQuery(filterData), page })
      .then((res) => {
        if (res.status === 'success') {
          setReportData(getData(res)?.results);
          setPagination({ current: page, total: getData(res)?.count, needAPICall: false });
        } else toast.error(getData(res)?.title || 'Something went wrong');
        setLoading(false);
      }).catch((e) => {
        captureException(e);
        setLoading(false);
      });
  };

  const handlePagination = ({ current, total }) => {
    setPagination({ current, total, needAPICall: true });
  };
  const dateRange = getDateRange({
    range: filterData?.selected?.date?.range,
    separator: '-',
  });

  const tableColumns = useMemo(
    () => columns(isMobile),
    [isMobile],
  );
  return (
    <ReportLayout
      title="Orders Report"
      description={`Changes to your account from <b>${dateRange}</b>, based on the date of the account change. Useful to record how your account changed.`}
      defaultRoute="/console/reports"
    >

      <ReportToolbar
        filterData={{ ...filterData }}
        setFilterData={setFilterData}
        hasExcludeStateFilter
        type="orders"
      />
      <Table
        columns={tableColumns}
        dataSource={reportData}
        locale={tableLocale}
        scroll={{ x: 2450 }}
        loading={loading}
        pagination={{
          total: pagination.total,
          showTotal: (total) => `${total} results`,
          size: 'small',
          showSizeChanger: false,
          current: pagination.current,
          defaultPageSize: 100,
        }}
        onChange={handlePagination}
        sticky
      />
    </ReportLayout>
  );
}

export default withGuard(OrdersReport, [PERMISSIONS.REPORTS], { redirect: true });
