import React, { useEffect, useState } from 'react';

import NewTabLink from 'components/common/NewTabLink';
import { ReactComponent as NoItemIcon } from 'assets/image/noItem.svg';
import { StyledWrapper } from './styles';
import { priceTransformer } from '../../../utils';

const TopMerchants = React.memo(({ topMerchants }) => (
  <StyledWrapper>
    <div className="top-merchant-box">
      <div className="top-merchant-top">
        <div className="top-merchant-title">Top merchants</div>
      </div>
    </div>

    <div className="table-wrapper">
      <table>
        <thead>
          <th>Merchant</th>
          <th>orders</th>
          <th>gmv</th>
        </thead>

        <tbody>
          {topMerchants?.map((t, index) => (
            <tr>
              <td>
                <div className="ellipsis">
                  <NewTabLink
                    label={t?.restaurant__name}
                    link={`/console/merchants/${t?.restaurant_id}`}
                  />
                </div>
              </td>
              <td className="strong">{t?.accepted_orders_in_date_range}</td>
              <td className="strong">{priceTransformer(t?.gmv)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {topMerchants?.length === 0 ? (
        <div className="no-item">
          <NoItemIcon />
          <p>There are no records to display for today.</p>
        </div>
      ) : null}
    </div>
  </StyledWrapper>
));

export default TopMerchants;
