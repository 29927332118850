import { ORDERS } from 'constants/permissions';
import OrderOverlay from 'containers/OrderOverlay';
import { usePermission } from 'hooks/usePermission';
import React, { useState } from 'react';

import BadgeLabel from 'components/common/BadgeLabel';
import NewTabLink from 'components/common/NewTabLink';
import PaginateTable from 'components/common/PaginateTable';
import moment from 'moment';
import {
  CANCELLED_BY_RESTAURANT,
  COMPLETED,
  IN_PROGRESS,
  NEW,
  ON_THE_WAY,
  READY,
} from 'constants/status';
import { useHistory, useParams } from 'react-router-dom';
import { priceTransformer } from 'utils';
import { StyledTable } from './styles';

function Table({
  categoryTitle,
  loading,
  items,
  pagination,
  resetPaging,
  refetch,
  ...props
}) {
  const { id: customerId, orderId } = useParams();
  const hasOrderPermission = usePermission(ORDERS);
  const [selectedOrder, setSelectedOrder] = useState();
  const history = useHistory();
  const handleStatus = (state) => {
    let statusName = '';
    let statusClassName = '';
    switch (state) {
    case NEW:
      statusName = 'New';
      statusClassName = 'New';
      break;
    case IN_PROGRESS:
      statusName = 'In Progress';
      statusClassName = 'in-progress';
      break;
    case ON_THE_WAY:
      statusName = 'On the Way';
      statusClassName = 'on-the-way';
      break;
    case READY:
      statusName = 'Ready';
      statusClassName = 'ready';
      break;
    case COMPLETED:
      statusName = 'Completed';
      statusClassName = 'completed';
      break;
    case CANCELLED_BY_RESTAURANT:
      statusName = 'Cancelled';
      statusClassName = 'cancelled';
      break;

    default:
      break;
    }

    return (
      <BadgeLabel
        label={statusName}
        classes={`status status--${statusClassName?.toLowerCase()}`}
      />
    );
  };

  const handleFormatDate = (d) => (d ? moment(d).format('MMM D, hh:mm A') : '-');

  return (
    <StyledTable>
      <OrderOverlay
        orderId={orderId}
        onClose={() => {
          setSelectedOrder(null);
          history.push(`/console/customers/${customerId}`);
        }}
        data={selectedOrder}
        handleOrderUpdate={refetch}
      />
      {/* Table */}
      <PaginateTable
        loading={loading}
        headers={[
          { title: 'Order date', width: '216px' },
          { title: 'Amount', width: '184px' },
          { title: 'Status', width: '184px' },
          { title: 'Merchant', width: 'auto' },
        ]}
        dataArray={items}
        rowCreators={[
          (row, index) => (
            <div className="">{handleFormatDate(row.state_modified)}</div>
          ),
          (row, index) => (
            <div className="strong">{priceTransformer(row.value)}</div>
          ),
          (row, index) => <div className="">{handleStatus(row.state)}</div>,
          (row, index) => (
            <div className="strong">
              <NewTabLink
                label={row.restaurant}
                link={`/console/merchants/${row?.id}`}
              />
            </div>
          ),
        ]}
        noRecord="There are no records to display."
        onClick={(record) => {
          if (hasOrderPermission) {
            setSelectedOrder(record);
            history.push(`/console/customers/${customerId}/orders/${record.id}`);
          }
        }}
        newRowClasses="new-row"
        dataPaging={pagination}
      />
    </StyledTable>
  );
}

export default Table;
