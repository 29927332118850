import {
  Col, Flex, message, Row,
} from 'antd';
import useIsDesktop from 'hooks/useIsDesktop';
import { useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import { getHttpErrorMessage } from 'services/http';
import React, { useEffect, useState } from 'react';
import { fetchOrderDetail } from '../requests';
import Actions from './Actions';
import PaymentDetail from './PaymentDetail';
import GeneralDetail from './GeneralDetail';
import OrderDetail from './OrderDetail';

function Form() {
  const isDesktop = useIsDesktop();
  const [messageApi, contextHolder] = message.useMessage();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const { orderNumber } = useParams();

  const fetchOrder = () => {
    setLoading(true);
    fetchOrderDetail(orderNumber).then((res) => {
      setOrder(res.data);
    }).catch((e) => {
      captureException(e);
      messageApi.open({ type: 'error', content: getHttpErrorMessage(e) });
    })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchOrder();
  }, []);

  return (
    <Row className="bg-[#F5F8F7] border border-slate-900 rounded-sm p-2 lg:p-8" gutter={[16, 16]}>
      <Col xs={24} lg={14}>
        <Flex vertical gap={24}>
          <GeneralDetail order={order} loading={loading} />
          <OrderDetail orderItems={order?.order_items} loading={loading} />
        </Flex>
      </Col>
      <Col xs={24} lg={6} offset={isDesktop ? 4 : 0} className="flex flex-column align-items-lg-end gap-4">
        <PaymentDetail order={order} loading={loading} />
        <Actions order={order} loading={loading} fetchOrder={fetchOrder} messageApi={messageApi} />
      </Col>
      {contextHolder}
    </Row>
  );
}
export default Form;
