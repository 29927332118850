import {
  Flex, Form as AntForm, Input,
  Space,
  Typography,
  Button,
} from 'antd';
import SuccessDocumentIcon from 'components/SuccessDocument';
import React from 'react';
import { useHistory } from 'react-router-dom';

function Results() {
  const history = useHistory();
  return (
    <Space direction="vertical" align="center" className="my-10 mx-5 w-full">
      <SuccessDocumentIcon />
      <Typography.Title level={3}>Your merchant’s data has been saved</Typography.Title>
      <Typography.Paragraph>We will notify you of the import results via the provided email address.</Typography.Paragraph>
      <Button
        onClick={() => {
          history.push('/console/merchants/');
        }}
      >
        Back to merchants
      </Button>
    </Space>
  );
}
export default Results;
